import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import "./ContactPage.scss";
import ContactCard from '../../components/presentational/contact-card/ContactCard';
import MapContainer from '../../components/presentational/map-container/MapContainer';
import HPTextCol from '../../components/presentational/hp-text-col/HPTextCol';

const _data = require('../../app-data/contact-page.js');

export class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeCity: "goteborg",
            adress: { street: "Stora Badhusgatan 18 - 20, 10tr", postNrCity: "411 21 Göteborg" },
            phone: "+46 31 68 82 90",
            fax: "",
            defaultCenter: {
                lat: 57.704110,
                lng: 11.956500
            }
        };
        this.setCity = this.setCity.bind(this);
    }
    setCity(e) {
        switch (e.target.textContent) {
            case "Göteborg":
                this.setState({
                    activeCity: "goteborg",
                    adress: { street: "Stora Badhusgatan 18 - 20, 10tr", postNrCity: "411 21 Göteborg" },
                    phone: "+46 31 68 82 90",
                    fax: "",
                    defaultCenter: {
                        lat: 57.704110,
                        lng: 11.956500
                    }
                })
                break;
            default:
                this.setState({
                    activeCity: "stockholm",
                    adress: { street: "Grev Turegatan 14", postNrCity: "114 46 Stockholm" },
                    phone: "+46 8 458 22 30",
                    fax: "+46 8 458 22 39",
                    defaultCenter: {
                        lat: 59.335840,
                        lng: 18.075720
                    }
                })
                break;
        }
    };
    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
            return false;
        }
        else {
            return true;
        }
    };
    render() {
        return (
            <div id="contactPage">               
                <Row className="header-row" style={{ backgroundImage: "url(content/images/private-savings-page/bg.png)" }}>                                    
                    <HPTextCol {..._data.HpTextData} />
                </Row>
                <Row className="contact-page-map-row">
                    <MapContainer googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDp1E805Pz4WAqHNfICyAWoNR2a8xtdbLU&libraries=places"
                        loadingElement={<div style={{ height: "100%", width: "100%" }}></div>}
                        containerElement={<div style={{ height: "100%", width: "100%" }}></div>}
                        mapElement={<div style={{ height: "100%", width: "100%" }}></div>}
                        defaultCenter={this.state.defaultCenter} />
                </Row>
                <Row className="justify-content-center">
                    <Col lg={9}>
                        <ContactCard {...this.state} handler={this.setCity} activeCity={this.state.activeCity} />
                    </Col>
                </Row>
            </div>
        );
    }
}
