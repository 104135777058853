import React from 'react';
import { Row, Col } from 'reactstrap';
import "./ContentNavigationRow.scss";
import HPTextCol from "../hp-text-col/HPTextCol";

const ContentNavigationRow = (props) => {
    return (
        <Row className="content-navigation-row">
            <Col className="text-center" lg={props.imageLgColSize} xl={props.imageXlColSize}>
                <Row className="--justify-content-sm-center">
                    <Col xs="12" style={props.imgAlignRight ? { "textAlign": "right" } : { "textAlign": "left" }}>
                        <img src={props.imageSrc} className="img-fluid" alt="content.png" />
                    </Col>
                </Row>
            </Col>
            <HPTextCol {...props.hPTextColData} />
        </Row>
    )
}
export default ContentNavigationRow;