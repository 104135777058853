import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import AccordionRow from '../../components/statefull/accordion-row/AccordionRow';

import "./QAndAPage.scss";

const _data = require('../../app-data/q-and-a-page.js');


export class QAndAPage extends Component {

  render() {
    return (
      <div id="qAndAPage">
        <ArticleHeaderRow {..._data.ArticleHeaderRowData} />
        <Row className="--margin-bottom-3">
          <Col xl={{ size: 10, offset: 2 }}>
            <h4 className="--margin-bottom-3">Om Fundroid</h4>           
            {_data.AccordionRowData.map(item => <AccordionRow key={item.question} {...item} />)}
          </Col>
        </Row>      
      </div>
    );
  }
}
