export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/legal-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/law.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Legal information",
    }
};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Viktig information om Fundroid",
    bodyText: {
        paragraphs: ["Fundroid är en finansiell tjänst i form av ett diskretionärt portföljförvaltningsuppdrag (”Tjänsten”) som tillhandahålls och utförs av Strivo AB, org. nr. 556759-1721, www.strivo.se (nedan ”Strivo” eller ”Bolaget”). Tjänsten innebär att Bolaget har fått mandat att för kunds räkning löpande genomföra placeringsbeslut avseende Tjänsten med utgångspunkt från den förvaltningsmodell som Bolaget har utvecklat och i enlighet med Tjänstens avtal och placeringsreglemente. Historisk utveckling är inte någon garanti för framtida utveckling. En investering i Tjänsten är förknippad med risk och det investerad kapital som placeras i Tjänsten kan både öka och minska i värde, vilket innebär att Bolaget inte kan garantera att kunden får tillbaka hela det investerade kapitalet eller garantera framtida avkastning. Då avkastning från Tjänsten kan vara skattepliktig bör kund kontakta professionell rådgivare för att utreda ev. skattemässiga konsekvenser av en investering i Tjänsten utifrån kunds egna förhållande.",
            "Denna webbplats utgör endast marknadsföring och ger inte en komplett bild av Tjänsten.", "Informationen som finns på denna webbplats skall därför inte ensam utgöra underlag för investeringsbeslut. Bolaget ansvarar inte för eventuella fel i informationen eller förluster som uppkommer genom att någon följer vad som sagts på denna webbplats eller använder sig av uppgifter lämnade här."]
    }
},
{
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Lämplighetsprövning",
    bodyText: { paragraphs: ["Bolaget skall säkerställa, innan en eventuell investering i Tjänsten görs, att en bedömning ha gjorts av Kundens lämplighet att investera i Tjänsten utifrån dennes förutsättningar, genom en s.k. lämplighetsprövning. Tjänsten är endast lämplig för kunder som har investeringsmål som stämmer överens med den aktuella Tjänstens erbjudande, tillhör den aktuella målgruppen som Tjänsten vänder sig till, samt har den finansiella styrkan att bära de risker som är förenade med sådana investeringar."] }
},
{
    id: 3,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Hållbarhet",
    bodyText: {
        paragraphs: ["Fundroid är en diskretionär portföljförvaltningstjänst där investering i underliggande tillgångar bestäms utifrån en algoritmbaserad förvaltningsmodell. Urvalet av underliggande värdepappersfonder beaktar inte hållbarhetsfaktorer i enlighet med förordning (EU) 2019/2088 (disclosureförordningen), utan styrs av kvantitativa data, som exempelvis historisk avkastning."
            , "Syftet med Fundroids investeringsinriktning är att nå god avkastning genom att investera i ett urval av de bästa och mest högavkastande värdepappersfonderna.För att ha tillgång till ett stort urval av värdepappersfonder har bedömning gjorts att det inte är relevant att integrera hållbarhetsrisker eller negativa konsekvenser för hållbar utveckling i investeringsbeslut för Fundroid då beaktandet av dessa faktorer skulle begränsa urvalet av tillgängliga värdepappersfonder."
            , "De värdepappersfonder som Fundroid investerar i kan ta hänsyn till ESG - faktorer och hållbarhetsrisker vid genomförandet av sin investeringspolicy men dessa utgör inte faktorer i Fundroids beslutsprocess vid val av fonder som sedan investeras i. "
            ,"Denna finansiella produkts underliggande investeringar beaktar inte EU-kriterierna för miljömässigt hållbara ekonomiska verksamheter. "]
    }
},
{
    id: 4,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Om Strivo",
    bodyText: { paragraphs: ["Strivo AB, org. nr. 556759-1721 är ett fristående svenskt värdepappersbolag som står under Finansinspektionens tillsyn (institutsnr: 35238) sedan 2010 och har tillstånd att bedriva verksamheten utifrån lagen om värdepappersmarknaden. Strivo har bland annat tillstånd att utföra handel och förvaring av finansiella instrument samt förvaring av kunders medel med redovisningsskyldighet. Kundmedel förvaras på klientmedelskonto hos Swedbank AB (publ) som omfattas av Banken och omfattas av statliga Riksgäldens investerargaranti."] }
},
{
    id: 5,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    colStyles: { marginBottom: 0 },
    header: "Tillstånd",
    bodyText: { paragraphs: ["Strivo har följande tillstånd från Finansinspektionen:"] }
}];

export const ListUlData = {
    ulStyles: {},
    lisStyles: {},
    listItems: ["Mottagande och vidarebefordran av order avseende finansiella instrument (2010-07-01)",
        "Utförande av order avseende finansiella instrument på kunders uppdrag (2010-07-01)",
        "Placering av finansiella instrument utan fast åtagande (2010-07-01)",
        "Förvara finansiella instrument och ta emot medel med redovisningsskyldighet (2010-07-01)",
        "Diskretionär portföljförvaltning avseende finansiella instrument (2011-05-23)",
        "Handel med finansiella instrument för egen räkning (2013-03-22)",
        "Utföra valutatjänster i samband m investeringstjänster (2019-05-06)"]
};