export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/monthly-activity-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/monthly-activity-page/watch.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Regelbunden aktivitet – utan arbetsinsats",
    }
};

export const ArticleBodyRowData = {
    backgroundImagePath: "url(content/images/monthly-activity-page/body-bg.png)",
    hPTextColData: {
        colXlSize: { size: 8, offset: 2 },
        colLgSize: { size: 10, offset: 1 },
        header: "Fundroid – din personliga förvaltare",
        bodyText: {
            paragraphs: ["För alla oss som inte har tiden, intresset eller kanske kunskapen att följa med i vad som händer på börsen och vilka fonder som presterar bäst för tillfället så är fondroboten Fundroid ett bra alternativ. Fundroid byter automatiskt fonder åt dig varannan månad, baserat på vilka fonder som har högst fondbetyg och bäst avkastning. Fundroid passar lika bra för dig som vill åt en aktiv förvaltning av ditt pensionskapital i en försäkringsdepå, som för dig som är ute efter ett vanligt buffertsparande på ditt investeringssparkonto (ISK). Fundroid är din personliga förvaltare som följer dig genom livet och håller koll på ditt fondsparande åt dig. Enkelt, smart och billigt!",
                "Och du är självklart inte bunden på något, utan kan självklart närsomhelst göra uttag från din fondportfölj eller helt avsluta förvaltningen på ditt investeringssparkonto utan kostnad."]
        }
    }
};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    bodyText: { paragraphs: ["Genom Fundroid får du automatiskt en aktiv förvaltning av ditt spar- eller pensionskapital månad ut och månad in, år efter år, utan att det kräver någon arbetsinsats av dig. Välj själv om du vill koppla på månadssparande via autogiro eller bara sätta in ett engångsbelopp."] },
},
{
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },    
    header: "Vikten av att välja rätt fonder",
    bodyText: { paragraphs: ["För många av oss kan det ibland vara svårt att få livspusslet att gå ihop och tiden att räcka till. Förutom arbetslivets och vardagens måsten och aktiviteter, ska man även ha tid att se över sitt sparande med jämna mellanrum. Inte minst är detta viktigt när det gäller ditt långsiktiga pensionssparande eftersom små skillnader i avkastning över en längre tidsperiod kan få stor betydelse för hur du lever ditt liv som framtida pensionär. Men den fond som är bäst en månad är inte nödvändigtvis den bästa fonden månaden efter. Att bli sittande med dyra och dåliga fonder under en längre tidsperiod kan vara förödande för ditt sparande."] },
},
{
    id: 3,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Fundroid – din personliga förvaltare",
    bodyText: {
        paragraphs: [
        "För alla oss som inte har tiden, intresset eller kanske kunskapen att följa med i vad som händer på börsen och vilka fonder som presterar bäst för tillfället så är fondroboten Fundroid ett bra alternativ. Fundroid byter automatiskt fonder åt dig varannan månad, baserat på vilka fonder som har högst fondbetyg och bäst avkastning. Fundroid passar lika bra för dig som vill åt en aktiv förvaltning av ditt pensionskapital i en försäkringsdepå, som för dig som är ute efter ett vanligt buffertsparande på ditt investeringssparkonto (ISK). Fundroid är din personliga förvaltare som följer dig genom livet och håller koll på ditt fondsparande åt dig. Enkelt, smart och billigt!",        
        "Och du är självklart inte bunden på något, utan kan självklart närsomhelst göra uttag från din fondportfölj eller helt avsluta förvaltningen på ditt investeringssparkonto utan kostnad."         
        ]
    }
}];
