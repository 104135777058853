export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/monthly-activity-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/historical-return-page/edit-curves.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Historisk avkastning",
    }
};

export const DataPoints = {
    fundroid: [
        { date: "2008-02-04", point: 100.00 },
        { date: "2008-03-03", point: 100.10 },
        { date: "2008-04-01", point: 98.81 },
        { date: "2008-05-02", point: 100.57 },
        { date: "2008-06-02", point: 102.23 },
        { date: "2008-07-01", point: 93.74 },
        { date: "2008-08-01", point: 92.12 },
        { date: "2008-09-01", point: 91.82 },
        { date: "2008-10-01", point: 85.91 },
        { date: "2008-11-03", point: 84.92 },
        { date: "2008-12-01", point: 85.07 },
        { date: "2009-01-02", point: 89.37 },
        { date: "2009-02-02", point: 84.92 },
        { date: "2009-03-02", point: 81.71 },
        { date: "2009-04-01", point: 81.41 },
        { date: "2009-05-04", point: 93.63 },
        { date: "2009-06-01", point: 100.81 },
        { date: "2009-07-01", point: 99.67 },
        { date: "2009-08-03", point: 104.10 },
        { date: "2009-09-01", point: 105.39 },
        { date: "2009-10-01", point: 107.92 },
        { date: "2009-11-02", point: 105.97 },
        { date: "2009-12-01", point: 109.60 },
        { date: "2010-01-04", point: 112.83 },
        { date: "2010-02-01", point: 110.94 },
        { date: "2010-03-01", point: 111.47 },
        { date: "2010-04-01", point: 117.41 },
        { date: "2010-05-03", point: 117.78 },
        { date: "2010-06-01", point: 110.40 },
        { date: "2010-07-01", point: 107.96 },
        { date: "2010-08-02", point: 113.15 },
        { date: "2010-09-01", point: 110.92 },
        { date: "2010-10-01", point: 114.67 },
        { date: "2010-10-29", point: 117.12 },
        { date: "2010-12-01", point: 118.43 },
        { date: "2011-01-03", point: 123.50 },
        { date: "2011-02-01", point: 120.64 },
        { date: "2011-03-01", point: 120.10 },
        { date: "2011-04-01", point: 120.55 },
        { date: "2011-04-29", point: 119.21 },
        { date: "2011-06-01", point: 119.74 },
        { date: "2011-07-01", point: 116.38 },
        { date: "2011-08-01", point: 115.46 },
        { date: "2011-09-01", point: 111.40 },
        { date: "2011-10-03", point: 109.87 },
        { date: "2011-11-01", point: 111.00 },
        { date: "2011-12-01", point: 113.18 },
        { date: "2012-01-02", point: 113.51 },
        { date: "2012-02-01", point: 120.72 },
        { date: "2012-03-01", point: 124.92 },
        { date: "2012-04-02", point: 124.07 },
        { date: "2012-05-02", point: 124.96 },
        { date: "2012-06-01", point: 122.03 },
        { date: "2012-07-02", point: 121.36 },
        { date: "2012-08-01", point: 122.26 },
        { date: "2012-09-03", point: 121.76 },
        { date: "2012-10-01", point: 125.03 },
        { date: "2021-10-31", point: 126.76 },
        { date: "2012-12-03", point: 127.87 },
        { date: "2013-01-02", point: 129.98 },
        { date: "2013-02-01", point: 130.42 },
        { date: "2013-03-01", point: 133.01 },
        { date: "2013-04-02", point: 135.54 },
        { date: "2013-05-02", point: 140.04 },
        { date: "2013-06-03", point: 141.98 },
        { date: "2013-07-01", point: 142.14 },
        { date: "2013-08-01", point: 147.69 },
        { date: "2013-09-02", point: 148.42 },
        { date: "2013-10-01", point: 151.77 },
        { date: "2013-11-01", point: 158.14 },
        { date: "2013-12-02", point: 160.40 },
        { date: "2014-01-02", point: 162.67 },
        { date: "2014-02-03", point: 162.50 },
        { date: "2014-03-03", point: 165.82 },
        { date: "2014-04-01", point: 169.78 },
        { date: "2014-05-02", point: 174.12 },
        { date: "2014-06-02", point: 181.53 },
        { date: "2014-07-01", point: 182.83 },
        { date: "2014-08-01", point: 183.19 },
        { date: "2014-09-01", point: 191.81 },
        { date: "2014-10-01", point: 187.30 },
        { date: "2014-11-03", point: 195.72 },
        { date: "2014-12-01", point: 199.42 },
        { date: "2015-01-02", point: 207.49 },
        { date: "2015-02-02", point: 214.39 },
        { date: "2015-03-02", point: 225.14 },
        { date: "2015-04-01", point: 229.32 },
        { date: "2015-05-04", point: 234.21 },
        { date: "2015-06-01", point: 238.17 },
        { date: "2015-07-01", point: 229.02 },
        { date: "2015-08-03", point: 238.57 },
        { date: "2015-09-01", point: 215.07 },
        { date: "2015-10-01", point: 213.73 },
        { date: "2015-11-02", point: 225.70 },
        { date: "2015-12-01", point: 233.27 },
        { date: "2016-01-04", point: 224.52 },
        { date: "2016-02-01", point: 216.74 },
        { date: "2016-03-01", point: 219.90 },
        { date: "2016-04-01", point: 220.91 },
        { date: "2016-05-02", point: 221.45 },
        { date: "2016-06-01", point: 227.47 },
        { date: "2016-07-01", point: 224.79 },
        { date: "2016-08-01", point: 238.85 },
        { date: "2016-09-01", point: 240.65 },
        { date: "2016-10-03", point: 247.02 },
        { date: "2016-11-01", point: 250.33 },
        { date: "2016-12-01", point: 251.39 },
        { date: "2016-12-30", point: 256.38 },
        { date: "2017-02-01", point: 256.53 },
        { date: "2017-03-01", point: 267.52 },
        { date: "2017-04-03", point: 268.92 },
        { date: "2017-05-02", point: 276.40 },
        { date: "2017-06-01", point: 282.61 },
        { date: "2017-07-03", point: 276.05 },
        { date: "2017-08-01", point: 275.31 },
        { date: "2017-08-31", point: 278.09 },
        { date: "2017-10-02", point: 285.16 },
        { date: "2017-10-31", point: 295.15 },
        { date: "2017-12-01", point: 293.01 },
        { date: "2017-12-29", point: 290.63 },
        { date: "2018-02-01", point: 293.77 },
        { date: "2018-03-01", point: 294.03 },
        { date: "2018-04-03", point: 293.09 },
        { date: "2018-05-02", point: 305.06 },
        { date: "2018-06-01", point: 311.24 },
        { date: "2018-07-02", point: 311.83 },
        { date: "2018-08-01", point: 313.38 },
        { date: "2018-09-03", point: 329.73 },
        { date: "2018-10-01", point: 321.95 },
        { date: "2018-11-01", point: 305.18 },
        { date: "2018-12-03", point: 310.63 },
        { date: "2019-01-02", point: 286.16 },
        { date: "2019-02-01", point: 296.88 },
        { date: "2019-03-01", point: 310.77 },
        { date: "2019-04-01", point: 319.36 },
        { date: "2019-05-01", point: 334.28 },
        { date: "2019-06-03", point: 309.77 },
        { date: "2019-07-01", point: 321.24 },
        { date: "2019-08-01", point: 325.98 },
        { date: "2019-09-02", point: 325.03 },
        { date: "2019-10-01", point: 325.36 },
        { date: "2019-11-01", point: 325.33 }
    ],
    benchmark: [
        { date: "2/4/2008", point: 100.00 },
        { date: "3/3/2008", point: 97.62 },
        { date: "4/1/2008", point: 93.86 },
        { date: "5/2/2008", point: 98.48 },
        { date: "6/2/2008", point: 99.68 },
        { date: "7/1/2008", point: 93.53 },
        { date: "8/1/2008", point: 92.67 },
        { date: "9/1/2008", point: 95.77 },
        { date: "10/1/2008", point: 92.46 },
        { date: "11/3/2008", point: 85.74 },
        { date: "12/1/2008", point: 84.60 },
        { date: "1/2/2009", point: 86.31 },
        { date: "2/2/2009", point: 83.16 },
        { date: "3/2/2009", point: 82.34 },
        { date: "4/1/2009", point: 81.90 },
        { date: "5/4/2009", point: 87.44 },
        { date: "6/1/2009", point: 89.34 },
        { date: "7/1/2009", point: 90.98 },
        { date: "8/3/2009", point: 92.74 },
        { date: "9/1/2009", point: 93.65 },
        { date: "10/1/2009", point: 96.01 },
        { date: "11/2/2009", point: 95.54 },
        { date: "12/1/2009", point: 97.95 },
        { date: "1/4/2010", point: 101.31 },
        { date: "2/1/2010", point: 100.45 },
        { date: "3/1/2010", point: 99.32 },
        { date: "4/1/2010", point: 105.31 },
        { date: "5/3/2010", point: 106.74 },
        { date: "6/1/2010", point: 105.57 },
        { date: "7/1/2010", point: 102.67 },
        { date: "8/2/2010", point: 102.91 },
        { date: "9/1/2010", point: 102.55 },
        { date: "10/1/2010", point: 102.39 },
        { date: "10/29/2010", point: 104.51 },
        { date: "12/1/2010", point: 106.68 },
        { date: "1/3/2011", point: 108.50 },
        { date: "2/1/2011", point: 106.00 },
        { date: "3/1/2011", point: 107.14 },
        { date: "4/1/2011", point: 107.07 },
        { date: "4/29/2011", point: 106.88 },
        { date: "6/1/2011", point: 107.82 },
        { date: "7/1/2011", point: 108.32 },
        { date: "8/1/2011", point: 107.66 },
        { date: "9/1/2011", point: 102.59 },
        { date: "10/3/2011", point: 101.73 },
        { date: "11/1/2011", point: 104.72 },
        { date: "12/1/2011", point: 106.18 },
        { date: "1/2/2012", point: 107.61 },
        { date: "2/1/2012", point: 111.80 },
        { date: "3/1/2012", point: 112.74 },
        { date: "4/2/2012", point: 113.98 },
        { date: "5/2/2012", point: 114.64 },
        { date: "6/1/2012", point: 114.35 },
        { date: "7/2/2012", point: 113.33 },
        { date: "8/1/2012", point: 113.37 },
        { date: "9/3/2012", point: 113.25 },
        { date: "10/1/2012", point: 115.13 },
        { date: "10/31/2012", point: 115.54 },
        { date: "12/3/2012", point: 117.05 },
        { date: "1/2/2013", point: 117.07 },
        { date: "2/1/2013", point: 118.52 },
        { date: "3/1/2013", point: 119.91 },
        { date: "4/2/2013", point: 122.88 },
        { date: "5/2/2013", point: 125.39 },
        { date: "6/3/2013", point: 127.20 },
        { date: "7/1/2013", point: 125.37 },
        { date: "8/1/2013", point: 126.96 },
        { date: "9/2/2013", point: 125.98 },
        { date: "10/1/2013", point: 127.98 },
        { date: "11/1/2013", point: 132.87 },
        { date: "12/2/2013", point: 135.48 },
        { date: "1/2/2014", point: 135.20 },
        { date: "2/3/2014", point: 133.94 },
        { date: "3/3/2014", point: 136.64 },
        { date: "4/1/2014", point: 138.61 },
        { date: "5/2/2014", point: 140.26 },
        { date: "6/2/2014", point: 145.60 },
        { date: "7/1/2014", point: 148.27 },
        { date: "8/1/2014", point: 150.59 },
        { date: "9/1/2014", point: 155.09 },
        { date: "10/1/2014", point: 154.75 },
        { date: "11/3/2014", point: 159.58 },
        { date: "12/1/2014", point: 162.56 },
        { date: "1/2/2015", point: 166.87 },
        { date: "2/2/2015", point: 172.92 },
        { date: "3/2/2015", point: 181.09 },
        { date: "4/1/2015", point: 184.82 },
        { date: "5/4/2015", point: 184.00 },
        { date: "6/1/2015", point: 186.70 },
        { date: "7/1/2015", point: 178.84 },
        { date: "8/3/2015", point: 184.86 },
        { date: "9/1/2015", point: 174.55 },
        { date: "10/1/2015", point: 168.34 },
        { date: "11/2/2015", point: 179.78 },
        { date: "12/1/2015", point: 182.23 },
        { date: "1/4/2016", point: 174.70 },
        { date: "2/1/2016", point: 169.81 },
        { date: "3/1/2016", point: 169.72 },
        { date: "4/1/2016", point: 171.58 },
        { date: "5/2/2016", point: 172.00 },
        { date: "6/1/2016", point: 177.97 },
        { date: "7/1/2016", point: 180.30 },
        { date: "8/1/2016", point: 187.73 },
        { date: "9/1/2016", point: 188.96 },
        { date: "10/3/2016", point: 189.65 },
        { date: "11/1/2016", point: 194.21 },
        { date: "12/1/2016", point: 198.39 },
        { date: "12/30/2016", point: 199.11 },
        { date: "2/1/2017", point: 196.74 },
        { date: "3/1/2017", point: 206.14 },
        { date: "4/3/2017", point: 206.48 },
        { date: "5/2/2017", point: 207.77 },
        { date: "6/1/2017", point: 208.29 },
        { date: "7/3/2017", point: 204.25 },
        { date: "8/1/2017", point: 201.85 },
        { date: "8/31/2017", point: 200.34 },
        { date: "10/2/2017", point: 207.21 },
        { date: "10/31/2017", point: 214.91 },
        { date: "12/1/2017", point: 217.69 },
        { date: "12/29/2017", point: 217.31 },
        { date: "2/1/2018", point: 218.71 },
        { date: "3/1/2018", point: 220.94 },
        { date: "4/3/2018", point: 220.11 },
        { date: "5/2/2018", point: 229.05 },
        { date: "6/1/2018", point: 232.14 },
        { date: "7/2/2018", point: 233.28 },
        { date: "8/1/2018", point: 234.67 },
        { date: "9/3/2018", point: 243.75 },
        { date: "10/1/2018", point: 238.91 },
        { date: "11/1/2018", point: 229.74 },
        { date: "12/3/2018", point: 232.12 },
        { date: "1/2/2019", point: 215.64 },
        { date: "2/1/2019", point: 232.52 },
        { date: "3/1/2019", point: 240.93 },
        { date: "4/1/2019", point: 244.64 },
        { date: "5/1/2019", point: 255.60 },
        { date: "6/3/2019", point: 245.38 },
        { date: "7/1/2019", point: 252.57 },
        { date: "8/1/2019", point: 260.10 },
        { date: "9/2/2019", point: 260.45 },
        { date: "10/1/2019", point: 264.75 },
        { date: "11/1/2019", point: 265.65 },


    ]
}

