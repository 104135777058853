import React from 'react';
import { Row, Col } from 'reactstrap';
import "./FundroidExplanatory.scss"
const FundroidExplanatory = (props) => {
    return (
        <div className="fundroid-explanatoy">
          <Row>
            <Col xs="auto">
              <img className="img-fluid" src="content/images/about-page/globe.jpg" alt="globe.jpg" />
              <h5>100% automatisk förvaltning</h5>
            </Col>
            <Col lg={{ offset: 1 }} xs="auto">
              <img className="img-fluid" src="content/images/about-page/board.jpg" alt="globe.jpg" />
              <h5>Endast fonder med toppresultat</h5>
            </Col>
          </Row>
          <Row>
            <Col xs="auto" className="--align-content-center">
              <img className="img-fluid" src="content/images/about-page/globe-wheel.jpg" alt="globe.jpg" />
              <h5>Fonder från hela världen</h5>              
            </Col>
            <Col lg={3}>
              <img id="infographics" className="img-fluid" src="content/images/about-page/DF_infographics.png" alt="globe.jpg" />
            </Col>
            <Col xs="auto" className="--align-content-center">              
              <img className="img-fluid" src="content/images/about-page/scale.jpg" alt="globe.jpg" />
              <h5>Ombalansering varannan månad</h5>
            </Col>
          </Row>
          <Row className="--margin-bottom-3">
            <Col xs="auto">
              <img className="img-fluid" src="content/images/about-page/document-pen.jpg" alt="globe.jpg" />
              <h5>Ingen bindningstid</h5>
            </Col>
            <Col lg={{ offset: 1 }} xs="auto">
              <img className="img-fluid" src="content/images/about-page/avatar.jpg" alt="globe.jpg" />
              <h5>Möjlighet till personlig service</h5>
            </Col>
          </Row>
        </div>
    )
}
export default FundroidExplanatory;