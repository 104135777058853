import React, { Component } from 'react';
import { Row } from "reactstrap";
import "./SavingsInstructionsPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import HPTextCol from '../../components/presentational/hp-text-col/HPTextCol';


const _data = require('../../app-data/savings-instructions-page.js');

export class SavingsInstructionsPage extends Component {

    render() {
        const hpText = _data.HPTextColData ? _data.HPTextColData.map(col => <Row key={col.id}><HPTextCol {...col} /></Row>) : "";
        return (
            <div id="savingsInstructionsPage">
                <ArticleHeaderRow {..._data.ArticleHeaderRowData} />
                {hpText}               
            </div>
        );
    }
}
