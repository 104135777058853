import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import "./StartSavingPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';

const _data = require('../../app-data/start-saving-page.js');

export class StartSavingPage extends Component {

    render() {
        return (
            <div id="startSavingPage">
                <ArticleHeaderRow {..._data.ArticleHeaderRowData} />
                <Row>
                    <Col lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
                        <p>För att börja investera med hjälp av fondroboten behöver du först öppna ett <em>investeringssparkonto</em> (endast tillgängligt för privatpersoner) eller en <em>försäkringsdepå</em> (tillgängligt för företag och privatpersoner) hos Strivo, samt teckna Fundroids avtal för diskretionär förvaltning.</p>
                        <p>Strivo är även skyldig att göra en lämplighetsbedömning där vi bedömer huruvida du som kund är lämplig att investera i Fundroid.</p>                        
                        <p>Vi rekommenderar att du tar hjälp av en rådgivare. Vi bedriver ingen egen rådgivning, men däremot samarbetar vi med ett hundratal olika rådgivare runtom i landet. Saknar du idag en rådgivare och vill komma i kontakt med en lokal rådgivare så är du därför varmt välkommen att kontakta oss på  <a className="--text-color-gold" href="mailto:kundsupport@strivo.se">kundsupport@strivo.se</a>.</p>
                    </Col>
                </Row>
            </div>
        );
    }
}
