import React, { Component } from 'react';
import { Row } from "reactstrap";
import "./MonthlyActivityPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import ArticleBodyRow from '../../components/presentational/article-body-row/ArticleBodyRow';
import HPTextCol from '../../components/presentational/hp-text-col/HPTextCol';

const _data = require('../../app-data/monthly-activity-page.js');

export class MonthlyActivityPage extends Component {

  render() {
    let firstHpText = "";
    let secondHpText = "";
    if(_data.HPTextColData){
      firstHpText = _data.HPTextColData.slice(0,2).map(col => <Row key={col.id}><HPTextCol {...col}/></Row>);
      secondHpText = _data.HPTextColData.slice(2, _data.HPTextColData.length).map(col => <Row key={col.id}><HPTextCol {...col}/></Row>);
    }
    return (
      <div id="monthlyActivityPage">
        <ArticleHeaderRow {..._data.ArticleHeaderRowData} />       
        {firstHpText}
        <ArticleBodyRow {..._data.ArticleBodyRowData} />       
        {secondHpText}
      </div>
    );
  }
}
