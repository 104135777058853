import React, { Component } from 'react';
import { Row } from "reactstrap";
import "./PensionSavingsPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import HPTextCol from '../../components/presentational/hp-text-col/HPTextCol';

const _data = require('../../app-data/pension-savings-page.js');

export class PensionSavingsPage extends Component {

  render() {
    let firstHpText = "";    
    if(_data.HPTextColData){
      firstHpText = _data.HPTextColData.map(col => <Row key={col.id}><HPTextCol {...col}/></Row>);  
    }
    return (
      <div id="pensionSavingsPage">
        <ArticleHeaderRow {..._data.ArticleHeaderRowData} />       
        {firstHpText}         
      </div>
    );
  }
}
