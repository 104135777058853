export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/legal-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/cookies.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Om cookies (kakor) på fundroid.se",
    }
};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    bodyText: {
        paragraphs: ['Enligt lagen om elektronisk kommunikation ska alla som besöker en webbplats med cookies få information om att webbplatsen använder cookies och vad de används för. Besökaren ska också samtycka till att cookies används.']
    }
},
{
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Cookies",
    bodyText: {
        paragraphs: ["Cookies är små textfiler som sparas på din dator. Cookies används för att bland annat underlätta funktionaliteten på webbplatsen. En webbplats kan bara läsa en cookie den själv skapat. Ofta behövs cookies för rent grundläggande funktioner hos en webbplats, till exempel för att se till att ett val du gjort på en webbsida följer med när du går vidare till nästa. Cookies kan inte ställa till med någon skada."]

    }
},
{
    id: 3,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Det finns i princip två typer av cookies:",
    bodyText: {
        paragraphs: ["Sessionscookies, som lagras tillfälligt i datorn under den tid som du är kvar på webbplatsen. De försvinner när webbläsaren stängs.",
            "Cookies som lagras i datorn under en längre tid, fram till ett förbestämt utgångsdatum. De kallas ibland för varaktiga cookies."]
    }
},
{
    id: 4,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Samtycke till att cookies används",
    bodyText: {
        paragraphs: ["Samtycke till att cookies används lämnas genom att du använder Fundroids webbplats och att du accepterar cookies i webbläsaren.",
            "Om du inte samtycker till att cookies används kan du stänga av cookies i din webbläsares säkerhetsinställningar. Det innebär att du automatiskt nekar till lagring av cookies eller informeras varje gång en webbplats begär att få lagra en cookie på din dator. Genom webbläsaren kan du också radera cookies som lagrats tidigare. Se webbläsarens hjälpsidor för mer information.",
            "Om du inte samtycker till cookies kan funktionaliteten på Fundroids webbplats begränsas."]
    }
},
{
    id: 5,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Fundroids användning av cookies",
    bodyText: { paragraphs: ["På Fundroids webbplats används cookies av rent tekniska skäl för att göra webbplatsen mer funktionell. Cookies används endast när det är nödvändigt. Om en funktion går att använda utan cookies och cookies går att ta bort utan att det medför orimliga kostnader eller större försämringar för användarna ska det göras."] }
},
{
    id: 6,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Cookies på Fundroids webbplats",
    headerStyles: {marginBottom: "0"}
},
{
    id: 7,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    cookieName: "_sifuklaro",
    cookieDomain: "fundroid.se",
    bodyText: { paragraphs: ["En varaktig cookie som används för att hålla reda på om du har godkänt cookies på webbplatsen. Om cookien finns hos dig kommer dialogrutan inte att visas igen. En fil sparas och ligger kvar på din dator under ett år. Cookien stannar kvar när webbläsaren stängs. Den kan inte användas för att spåra besök på andra webbplatser."] }
},
{
    id: 8,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Google analytics",
    headerStyles: {fontSize: "1.25rem"},
    bodyText: { paragraphs: ["Fundroid använder tredjepartscookies från Google analytics för att samla in statistik och information om vilka sidor på webbplatsen som användarna besöker."] }
},
{
    id: 9,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    cookieName: "_ga",
    cookieDomain: "fundroid.se",
    bodyText: { paragraphs: ["En varaktig cookie som används för identifiera användaren. En fil sparas och ligger kvar på din dator under två år. Cookien stannar kvar när webbläsaren stängs."] }
},
{
    id: 10,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    cookieName: "_gid",
    cookieDomain: "fundroid.se",
    bodyText: { paragraphs: ["En varaktig cookie som används för identifiera användaren. En fil sparas och ligger kvar på din dator under 24 timmar. Cookien stannar kvar när webbläsaren stängs."] }
},
{
    id: 11,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    cookieName: "_gat",
    cookieDomain: "fundroid.se",
    bodyText: { paragraphs: ["En varaktig cookie som används för att mäta anrop på sidan. En fil sparas och ligger kvar på din dator under en minut. Cookien stannar kvar när webbläsaren stängs."] }
},
{
    id: 12,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    cookieName: "__utma",
    cookieDomain: "fundroid.se",
    bodyText: { paragraphs: ["En varaktig cookie som används för att skilja användare och sessioner. Cookien uppdateras varje gång data skickas till Google Analytics. En fil sparas och ligger kvar på din dator under två år från skapad eller senast uppdaterad. Cookien stannar kvar när webbläsaren stängs."] }
},
{
    id: 13,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    cookieName: "__utmb",
    cookieDomain: "fundroid.se",
    bodyText: { paragraphs: ["En varaktig cookie som används för att identifiera nya sessioner/besök. Cookien uppdateras varje gång data skickas till Google Analytics. En fil sparas och ligger kvar på din dator under 30 min från skapad eller senast uppdaterad. Cookien stannar kvar när webbläsaren stängs."] }
},
{
    id: 14,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    cookieName: "__utmc",
    cookieDomain: "fundroid.se",
    bodyText: { paragraphs: ["En sessionscookie som används i samband med __utmb-cookien för att identifiera nya sessioner/besök Under tiden du är inne på en webbsida, lagras den temporärt i din dators minne och försvinner när webbläsaren stängs."] }
},
{
    id: 15,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    cookieName: "__utmt",
    cookieDomain: "fundroid.se",
    bodyText: { paragraphs: ["En varaktig cookie som används för att mäta anrop på sidan. En fil sparas och ligger kvar på din dator under tio minuter. Cookien stannar kvar när webbläsaren stängs."] }
},
{
    id: 16,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    cookieName: "__utmz",
    cookieDomain: "fundroid.se",
    bodyText: { paragraphs: ["En varaktig cookie som används för att lagra information om hur du anlände på webbplatsen. Cookien uppdateras varje gång data skickas till Google Analytics.En fil sparas och ligger kvar på din dator under sex månader från skapad eller senast uppdaterad. Cookien stannar kvar när webbläsaren stängs."] }
},
{
    id: 17,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "New Relic",
    headerStyles: {fontSize: "1.25rem"},
    bodyText: { paragraphs: ["Fundroid använder tredjepartscookies från New Relic för att samla in statistik och information om applikationsprestanda"] }
},
{
    id: 18,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },    
    cookieName: "JSESSIONID",
    cookieDomain: ".nr-data.net",
    bodyText: { paragraphs: ["En sessionscookie som används för att samla in statistik och information om applikationsprestanda Under tiden du är inne på en webbsida, lagras den temporärt i din dators minne och försvinner när webbläsaren stängs."] }
}];
