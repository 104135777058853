import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/shared/Layout';
import './css/index.scss'
import { LandingPage } from './view-components/landing-page/LandingPage';
import { QAndAPage } from './view-components/q-and-a-page/QAndAPage';
import { AboutPage } from './view-components/about-page/AboutPage';
import { MonthlyActivityPage } from './view-components/monthly-activity-page/MonthlyActivityPage';
import { LegalPage } from './view-components/legal-page/LegalPage';
import { ComplaintsPage } from './view-components/complaints-page/ComplaintsPage';
import { CostsPage } from './view-components/costs-page/CostsPage';
import { PersonalInformationPage } from './view-components/personal-information-page/PersonalInformationPage';
import { TermsPage } from './view-components/terms-page/TermsPage';
import { SavingsInstructionsPage } from './view-components/savings-instuctions-page/SavingsInstructionsPage';
import { CookiesPage } from './view-components/cookies-page/CookiesPage';
import { DepositGuaranteePage } from './view-components/deposit-guarantee-page/DepositGuaranteePage';
import { PrivateSavingsPage } from './view-components/private-savings-page/PrivateSavingsPage';
import { PensionSavingsPage } from './view-components/pension-savings-page/PensionSavingsPage';
import { ContactPage } from './view-components/contact-page/ContactPage';
import { HistoricalReturnPage } from "./view-components/historical-return-page/HistoricalReturnPage";
import { NotFoundPage } from './view-components/not-found-page/NotFoundPage';
import { StartSavingPage } from './view-components/start-saving-page/StartSavingPage';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route path='/om-fundroid' component={AboutPage} />
          <Route path='/fragor-och-svar' component={QAndAPage} />
          <Route path='/kontakta-oss' component={ContactPage} />
          <Route path='/regelbunden-aktivitet' component={MonthlyActivityPage} />
          <Route path='/legal-information' component={LegalPage} />
          <Route path='/synpunkter' component={ComplaintsPage} />
          <Route path='/avgifter' component={CostsPage} />
          <Route path='/personuppgifter' component={PersonalInformationPage} />
          <Route path='/anvandarvillkor' component={TermsPage} />
          <Route path='/sa-sparar-du' component={SavingsInstructionsPage} />
          <Route path='/cookies' component={CookiesPage} />
          <Route path='/insattningsgaranti' component={DepositGuaranteePage} />
          <Route path='/privat-sparande' component={PrivateSavingsPage} />
          <Route path='/pensionssparande' component={PensionSavingsPage} />
          <Route path='/historisk-avkastning' component={HistoricalReturnPage} />
          <Route path='/borja-spara' component={StartSavingPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Layout>
    );
  }
}
