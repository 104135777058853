import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import "./CostsPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import HPTextCol from '../../components/presentational/hp-text-col/HPTextCol';

const _data = require('../../app-data/costs-page.js');

export class CostsPage extends Component {

    render() {
        const hpText = _data.HPTextColData ? _data.HPTextColData.map(col => <Row key={col.id}><HPTextCol {...col} /></Row>) : "";
        return (
            <div id="costsPage">
                <ArticleHeaderRow {..._data.ArticleHeaderRowData} />
                {hpText}
                <Row className="justify-content-center" id="costsPageCosts">
                    <Col lg={10} xl={8}>
                        <Row>
                            <Col className="--margin-bottom-2" xs={12}>
                                <h5>Prisexempel (hypotetiskt):</h5>
                            </Col>
                        </Row>
                        <Row className="highlight-row">
                            <Col xs={12}>
                                <h6 className="--text-color-gold">Investerat belopp</h6><h5 style={{textAlign: "right"}}>10 000 kr</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Fast årlig förvaltningsavgift:</h6><h6 style={{ textAlign: "right" }}>0,30 % inkl. moms</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Årligt förvaltningsavgift i kr:</h6><h6 style={{ textAlign: "right" }}>30 kr</h6>
                            </Col>
                        </Row>
                        <Row className="--margin-bottom-3">
                            <Col xs={12}>
                                <h6>Överavkastning:</h6><h6 style={{ textAlign: "right" }}>10 %</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Överavkastning i kronor:</h6><h6 style={{ textAlign: "right" }}>1000 kr</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Prestationsbaserad avgift:</h6><h6 style={{ textAlign: "right" }}>20 % av överavkastningen</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Prestationsbaserad avgift i kr:</h6><h6 style={{ textAlign: "right" }}>200 kr</h6>
                            </Col>
                        </Row>
                        <Row className="highlight-row --margin-bottom-5">
                            <Col xs={12}>
                                <h6 className="--text-color-gold">Återstående överavkastning till kund:</h6><h5 style={{ textAlign: "right" }}>800 kr</h5>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-center" id="costsExample">
                    <Col lg={10} xl={8}>
                        <Row className="--margin-bottom-2">
                            <Col xs={12}>
                                <h4>Prislista</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Fast årlig förvaltningsavgift</h6><h6 style={{ textAlign: "right" }}>0,30 % inkl. moms</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Prestationsbaserad avgift:</h6><h6 style={{ textAlign: "right" }}>20 % av ev. överavkastning</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Avgifter på insättningar</h6><h6 style={{ textAlign: "right" }}>0 kr</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Avgifter på uttag</h6><h6 style={{ textAlign: "right" }}>0 kr</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Fondcourtage</h6><h6 style={{ textAlign: "right" }}>0 kr</h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h6>Depåavgift</h6><h6 style={{ textAlign: "right" }}>0 kr</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}
