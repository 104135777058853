import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import ContentNavigationRow from '../../components/presentational/content-navigation-row/ContentNavigationRow';
import PerformanceRow from "../../components/statefull/performance-row/PerformanceRow";
import HPTextCol from "../../components/presentational/hp-text-col/HPTextCol";
import ReasonRow from '../../components/presentational/reason-row/ReasonRow';
import "./LandingPage.scss";

const _data = require('../../app-data/landing-page.js');


export class LandingPage extends Component {
  static displayName = LandingPage.name;

  render() {
    let firstReasonsRow = "";
    let secondReasonsRow = "";
    if (_data.Reasons) {
      firstReasonsRow = _data.Reasons.slice(0, 3).map(r => <ReasonRow key={r} text={r} />);
      secondReasonsRow = _data.Reasons.slice(3, _data.Reasons.length).map(r => <ReasonRow key={r} text={r} />);
    }
    return (
      <div id="landingPage">
        <Row className="--margin-bottom-1">
          <Col className="text-center">
            <h1>Smart & enkelt sparande</h1>
            <h5 className="--text-color-primary">Få tid över till sånt som verkligen betyder något</h5>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
           <Link to="/borja-spara"><button id="backgroundOverlayButton" className="secondary --negative-margin-bottom-5">kom igång direkt</button></Link>
          </Col>
        </Row>
        <Row className="justify-content-center landingpage-background " style={{ backgroundImage: "url(content/images/landing-page/landingpageBackground.png)" }}>
          <Col xs={11} lg={8}>
            <Row>
              <Col xs={12}>
                <Row className="reasons-container">
                  <Col md={6}>
                    {firstReasonsRow}
                  </Col>
                  <Col md={6}>
                    {secondReasonsRow}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row id="landingPageOrangeWaveRow" style={{ backgroundImage: "url(content/images/landing-page/F_wave.png)" }}>
        </Row>
        {_data.ContentNavigationData.map(item => <ContentNavigationRow key={item.id} {...item} />)}
        <Row>
          <HPTextCol {..._data.HPTextColData[0]} />
          <Col lg={2}></Col>
        </Row>
        <br></br>
        <Row>
                <Col xs={6} lg={{ size: 4, offset: 1 }} xl={{ size: 3, offset: 2 }}>
                    <h6>Avkastning sedan start <span style={{ whiteSpace: "nowrap" }}>(2019-11-06)</span></h6>
          </Col>
          <Col xs={6} lg={3} xl={2}>
            <h6 className="text-center text-xl-right">Avkastning i år</h6>
          </Col>
        </Row>
        <PerformanceRow />
        <div id="landingpagePhone" style={{ backgroundImage: "url(content/images/landing-page/phonelandingpage.png)" }}></div>
        <Row id="landingpageBlueWaveRow" style={{ backgroundImage: "url(content/images/landing-page/bg.svg)" }}>
          <HPTextCol {..._data.HPTextColData[1]} />
          <Col lg={5}></Col>
        </Row>
      </div>
    );
  }
}
