export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/legal-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/hand-card.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Avgifter",
    }
};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Är billigt alltid bäst?",
    bodyText: {     
        paragraphs: ["Pris är viktigt. Men vi tror inte att det är så enkelt att billiga indexfonder alltid är bäst. Om en lite dyrare fond faktiskt ger en högre avkastning efter kostnader än en billigare fond – då är valet ganska enkelt. Vi tror på att investera i marknadens bästa fonder, men till rätt pris. Rätt pris för oss är ett pris som är lägre än ordinarie pris, vilket vi uppnår genom att Fundroid alltid nettohandlar de fonder som man investerar i."]
    }
},
{
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Nettohandlade fonder",
    bodyText: {
        paragraphs: ["För oss räcker det inte att välja ut de bästa fonderna och handla dem till ordinarie pris. Vi ser till att de handlas till nettopris, vilket i praktiken innebär en betydande rabatt på fondens ordinarie förvaltningsavgift för dig som kund. Detta kan vi uppnå genom att vi återbetalar 100 % av alla fondprovisioner och liknande tredjepartsersättningar som fondbolagen normalt sett betalar till depåinstitut och fondplattformar. Lägre avgifter och mer pengar till dig helt enkelt!",
            "Det innebär också att både du som kund och vi som förvaltare slipper onödiga intressekonflikter kopplade till fondprovisioner. Vårt enda incitament är att skapa en hög avkastning till dig som kund. Ganska självklart egentligen."]
    }
},
{
    id: 3,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Våra avgifter",
    bodyText: { paragraphs: ["För den aktiva förvaltningen tar Fundroid endast en fast årlig förvaltningsavgift på 0,30 %. I det fall Fundroid lyckas generera en överavkastning i relation till Fundroids jämförelseindexet betalar du som kund 20 % av överavkastningen i prestationsbaserad avgift över Fundroids jämförelseindex."] }
},
];

