import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import "./PerformanceRow.scss";
import * as _service from "../../../performance-helper/PerformanceHelper";
import PerformanceTextRow from "../../presentational/performance-text-row/PerformanceTextRow";
let state = {
    fundroidModelBriefcase: { returnSinceFundroidStart: 0, returnThisYear: 0 },
    compareIndex: { returnSinceFundroidStart: 0, returnThisYear: 0 },
    nasdaqStockholm: { returnSinceFundroidStart: 0, returnThisYear: 0 },
    lastBankdayUpdated: ""
};
export default class PerformanceRow extends Component {
    static returnIfPositiveNumberText(number) {

        if (number === 0) {
            return '-';

        }
        else if (number > 0) {
            return "+" + number.toString() + " %";
        }

        return number.toString() + " %";
    }

    constructor(props) {
        super(props);
        this.state = state;
    }
    componentDidMount() {
        if (JSON.stringify(this.state) === JSON.stringify({
            fundroidModelBriefcase: { returnSinceFundroidStart: 0, returnThisYear: 0 },
            compareIndex: { returnSinceFundroidStart: 0, returnThisYear: 0 },
            nasdaqStockholm: { returnSinceFundroidStart: 0, returnThisYear: 0 },
            lastBankdayUpdated: ""
        })) {
            this.populateAccountData();
        }
    }
    componentWillUnmount() {
        state = this.state;
    }

    render() {
        return (
            <Row className="performance-row">
                <Col lg={8} xl={{ size: 7, offset: 1 }}>
                    <PerformanceTextRow header="Fundroid modellportfölj"
                        headerColor="#f29782"
                        highlight={true}
                        secondColText={PerformanceRow.returnIfPositiveNumberText(this.state.fundroidModelBriefcase.returnSinceFundroidStart)}
                        thirdColText={PerformanceRow.returnIfPositiveNumberText(this.state.fundroidModelBriefcase.returnThisYear)} />
                    <PerformanceTextRow header="Jämförelseindex"
                        secondColText={PerformanceRow.returnIfPositiveNumberText(this.state.compareIndex.returnSinceFundroidStart)}
                        thirdColText={PerformanceRow.returnIfPositiveNumberText(this.state.compareIndex.returnThisYear)} />
                    <PerformanceTextRow header="Stockholmsbörsen"
                        secondColText={PerformanceRow.returnIfPositiveNumberText(this.state.nasdaqStockholm.returnSinceFundroidStart)}
                        thirdColText={PerformanceRow.returnIfPositiveNumberText(this.state.nasdaqStockholm.returnThisYear)} />
                </Col>
                <Col lg={8} xl={{ size: 7, offset: 1 }}>
                    <em style={{ fontSize: "12px" }}>Senast uppdaterad: {this.state.lastBankdayUpdated}</em>
                </Col>
            </Row>
        );
    }
    async populateAccountData() {
        let stateObject = {
            fundroidModelBriefcase: { returnSinceFundroidStart: 0, returnThisYear: 0 },
            compareIndex: { returnSinceFundroidStart: 0, returnThisYear: 0 },
            nasdaqStockholm: { returnSinceFundroidStart: 0, returnThisYear: 0 },
            lastBankdayUpdated: ""
        };
        let fData = await _service.getPerformanceData('account/get-performance/5000062/20/true');
        if (fData) {
            stateObject.fundroidModelBriefcase.returnSinceFundroidStart = fData[fData.length - 1].cumulativePerformance || 0;
            stateObject.lastBankdayUpdated = fData[fData.length - 1].date.split('T')[0];
            stateObject.compareIndex.returnSinceFundroidStart = fData[fData.length - 1].cumulativeBenchmarkPerformance || 0;
        }
        let fRetThisYear = await _service.getPerformanceData('account/get-performance/5000062/3/true');
        if (fRetThisYear) {
            stateObject.fundroidModelBriefcase.returnThisYear = fRetThisYear[fRetThisYear.length - 1].cumulativePerformance || 0;
            stateObject.compareIndex.returnThisYear = fRetThisYear[fRetThisYear.length - 1].cumulativeBenchmarkPerformance || 0;
        }
        let nData = await _service.getPerformanceData('account/get-omx-performance/1/True');
        if (nData) {
            stateObject.nasdaqStockholm.returnSinceFundroidStart = nData[nData.length - 1].price || 0;
        }

        let nThisYear = await _service.getPerformanceData('account/get-omx-performance/3/True');
        if (nThisYear) {
            stateObject.nasdaqStockholm.returnThisYear = nThisYear[nThisYear.length - 1].price || 0;
        }

        this.setState(() => stateObject);
    }

    getDateThisYearStart() {
        const currentDate = new Date();
        return new Date(currentDate.getFullYear(), 0, 1, 0, 0, 0);
    }
    getPerformancePercent(data) {
        let performanceObj = {
            returnSinceFundroidStart: 0,
            returnThisYear: 0
        };
        const performanceAll = (data[data.length - 1].startMarketValue - data[1].startMarketValue) / data[1].startMarketValue * 100;
        const resultAll = Math.round((performanceAll + Number.EPSILON) * 100) / 100;
        performanceObj.returnSinceFundroidStart = resultAll;
        const thisYearFirstDayDate = this.getDateThisYearStart();
        const today = new Date();
        const timeDifference = thisYearFirstDayDate.getTime() - today.getTime();
        const differenceInDays = (timeDifference / (1000 * 3600 * 24)) | 0;
        const performanceYear = (data[data.length - 1].startMarketValue - data[data.length + differenceInDays].startMarketValue) / data[data.length + differenceInDays].startMarketValue * 100;
        const resultYear = Math.round((performanceYear + Number.EPSILON) * 100) / 100;
        performanceObj.returnThisYear = resultYear;

        return performanceObj;
    }
}

