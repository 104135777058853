import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import "./TermsPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import HPTextCol from '../../components/presentational/hp-text-col/HPTextCol';


const _data = require('../../app-data/terms-page.js');

export class TermsPage extends Component {

    render() {
        const hpText = _data.HPTextColData ? _data.HPTextColData.map(col => <Row key={col.id}><HPTextCol {...col} /></Row>) : "";
        return (
            <div id="termsPage">
                <ArticleHeaderRow {..._data.ArticleHeaderRowData} />
                {hpText}
                <Row>
                    <Col lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
                        <h4 className="--margin-bottom-2">8. Kontaktuppgifter beträffande Webbplatsen</h4>

                        <h5>Strivo AB</h5>
                        <p>
                            Att: Declan Mac Guinness<br></br>
                                Stora Badhusgatan 18-20<br></br>
                                411 21 Göteborg<br></br>
                                <a className="--text-color-gold" href="mailto:info@strivo.se">info@strivo.se</a>
                        </p>

                    </Col>
                </Row>
            </div>
        );
    }
}
