export const Reasons = [
    "Modern & automatiserad robotförvaltning",
    "Brett urval av marknadens bästa fonder till rabatterat pris",
    "Transparent och rättvis avgiftsmodell",
    "Automatiska fondbyten varannan månad",
    "Kräver inget av din tid"
];

export const ContentNavigationData = [
    {
        id: 1,
        imageSrc: "/content/images/landing-page/clock.png",
        imageXlColSize: { size: 2, offset: 2 },
        imageLgColSize: { size: 2, offset: 1 },
        hPTextColData: {
            colXlSize: 6,
            colLgSize: 8,
            navigationButton: {
                linkUrl: "/regelbunden-aktivitet",
                buttonText: "läs mer",
                customButtonStyle: {
                    borderColor: "#f29782",
                    color: "#f29782"
                }
            },
            header: "Regelbunden aktivitet - utan arbetsinstats",
            bodyText: { paragraphs: ["Genom Fundroid får du automatiskt en aktiv förvaltning av ditt spar- eller pensionskapital månad ut och månad in, år efter år, utan att det kräver någon arbetsinsats av dig. Välj själv om du vill koppla på månadssparande via autogiro eller bara sätta in ett engångsbelopp."] },
        }

    },
    {
        id: 2,
        imageSrc: "/content/images/landing-page/shield.png",
        imgAlignRight: true,
        imageXlColSize: { size: 2, order: 2 },
        imageLgColSize: { size: 2, order: 2 },
        hPTextColData: {
            colXlSize: { size: 6, offset: 2 },
            colLgSize: { size: 6, offset: 1 },
            navigationButton: {
                linkUrl: "/insattningsgaranti",
                buttonText: "läs mer",
                customButtonStyle: {
                    borderColor: "#89c5cd",
                    color: "#89c5cd"
                }
            },
            header: "Insättningsgaranti & investerarskydd",
            bodyText: { paragraphs: ["Det kapital som är investerat i fonder är separerat från depåinstitutet och skyddat vid eventuell konkurs utan övre beloppsgräns. De eventuella likvida medel som från tid till annan är oinvesterade i tjänsten omfattas av {a}"] },
            textLink: {
                linkUrl: "https://www.riksgalden.se/sv/var-verksamhet/insattningsgarantin-och-investerarskyddet/sa-fungerar-investerarskyddet/",
                innerText: "investerarskyddet",
                linkStyle: { color: "#d5b266" },
                external: true
            },
        }
    },
    {
        id: 3,
        imageSrc: "/content/images/landing-page/coin.png",
        imageXlColSize: { size: 2, offset: 2 },
        imageLgColSize: { size: 2, offset: 1 },
        hPTextColData: {
            colXlSize: 6,
            colLgSize: 8,
            navigationButton: {
                linkUrl: "/privat-sparande",
                buttonText: "läs mer",
                customButtonStyle: {
                    "borderColor": "#ba7ba1",
                    "color": "#ba7ba1"
                }
            },
            header: "Privat sparande i investeringssparkonto",
            bodyText: { paragraphs: ["Som privatperson sparar du enkelt och effektivt i Fundroid genom ett s.k. investeringssparkonto (ISK)."] },
        }

    },
    {
        id: 4,
        imageSrc: "/content/images/landing-page/document.png",
        imgAlignRight: true,
        imageXlColSize: { size: 2, order: 2 },
        imageLgColSize: { size: 2, order: 2 },
        hPTextColData: {
            colXlSize: { size: 6, offset: 2 },
            colLgSize: { size: 6, offset: 1 },
            navigationButton: {
                linkUrl: "/pensionssparande",
                buttonText: "läs mer",
                customButtonStyle: {
                    "borderColor": "#d5b266",
                    "color": "#d5b266"
                },
            },
            header: "Pensionssparande i depåförsäkring",
            bodyText: { paragraphs: ["Om du önskar koppla på Fundroids moderna fondrobot på ditt tjänstepensionssparande så erbjuder vi en smidig lösning via depåförsäkring."] },
        },

    },
];

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 6, offset: 2 },
    colLgSize: { size: 8, offset: 1 },

    header: "Aktuell utveckling",
    bodyText: { paragraphs: ["Nedan visas aktuell utveckling för Fundroids modellportfölj sedan start. Din personliga Fundroid-portfölj följer modellportföljen så gott det går, men mindre avvikelser kan förekomma från tid till annan på grund av avrundningar, etc. Du kan alltid logga in och följa utvecklingen för din personliga Fundroid-portfölj."] },
},
{
    id: 2,
    navigationButton: {
        linkUrl: "/borja-spara",
        buttonText: "kom igång direkt",
        customButtonStyle: {
            "borderColor": "#ffffff",
            "color": "#262220", "boxShadow": " 0px 15px 40px 0 rgba(0, 0, 0, 0.16)"
        }
    },
    colXlSize: { size: 6, offset: 2 },
    colLgSize: { size: 7, offset: 1 },
    colXsSize: { size: 6 },
    header: "Aktiv förvaltning utan ansträngning",
    bodyText: { paragraphs: ["Fundroid är fondroboten för dig som söker en automatisk, prisvärd och kvalitativ förvaltning av ditt fondsparande, men som saknar tid och intresse att aktivt sköta förvaltningen på egen hand. Med Fundroid kan du tryggt luta dig tillbaka med vetskapen att du äger marknadens toppfonder."] },

}];

