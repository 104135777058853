export const HPTextColData = [{
    id: 1,
    navigationButton: {
        linkUrl: "/borja-spara",
        buttonText: "kom igång direkt",
        customButtonStyle: {
            height: "50px", width: "170px", borderRadius: "27px", fontSize: "14px", borderColor: "#89c5cd",
            color: "#ffffff", backgroundColor: "#89c5cd"
        }
    },
    colXlSize: { size: 7, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Aktiv förvaltning utan ansträngning",
    headerStyles: { fontSize: "2rem", fontWeight: "600" },   
    bodyText: { paragraphs: ["Fundroid är fondroboten för dig som söker en automatisk, prisvärd och kvalitativ förvaltning av ditt fondsparande, men som saknar tid och intresse att aktivt sköta förvaltningen på egen hand. Med Fundroid kan du tryggt luta dig tillbaka med vetskapen att du äger marknadens toppfonder."] },
}, {
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Vad är en fondrobot?",
    bodyText: { paragraphs: ["En fondrobot automatiserar ditt sparande i fonder. Genom att använda en fondrobot kan du nå en hög avkastning på dina sparpengar utan att själv behöva anstränga dig. En stor fördel med fondrobotar är att de – till skillnad från oss människor – inte har känslor och är fullständigt rationella. De investerar systematiskt och regelbaserat. Faktum är att en stor anledning till otillfredsställande avkastning har sin grund i psykologiska faktorer hos oss människor. Det kan till exempel handla om girighet eller övertro på vår egen magkänsla – saker som alltså undviks med en fondrobot!",] }
},
{
    id: 3,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Varför Fundroid?",
    bodyText: { paragraphs: ["Nästan alla fondrobotar på marknaden investerar uteslutande i indexfonder eller andra indexbaserade instrument. Även om indexfonder är ett fullgott alternativ för många investerare är det viktigt att komma ihåg att index är – och alltid kommer att vara – genomsnittet. Idén med Fundroid var att skapa en fondrobot som istället väljer bland de bästa och mest högavkastande fonderna. Med hjälp av modern teknologi väljer Fundroid automatiskt ut de allra bästa fonderna varannan månad. Urvalsmodellen tar hänsyn till parametrar såsom, riskjusterad avkastning, kostnader och fondkategori. Resultatet av denna så kallade ”momentumstrategi” är att du som investerare alltid äger marknadens toppfonder."] }
},
{
    id: 4,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    headerStyles: { fontSize: "1.1rem", fontWeight: "500"},
    header: "Prisvärd förvaltning och rättvis avgiftsmodell",
    bodyText: { paragraphs: ["Genom modern teknik kan vi automatisera mycket av hanteringen och därigenom hålla nere den fasta avgiften. Vår uppsida finns om vi lyckas särskilt väl med förvaltningen. Givet en överavkastning i relation till jämförelseindexet tar vi en ut en mindre del och resten tillfaller dig som kund. Rättvist och transparent!"] }
}];