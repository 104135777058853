import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import "./PersonalInformationPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import HPTextCol from '../../components/presentational/hp-text-col/HPTextCol';


const _data = require('../../app-data/personal-information-page.js');

export class PersonalInformationPage extends Component {

    render() {
        const hpText = _data.HPTextColData ? _data.HPTextColData.map(col => <Row key={col.id}><HPTextCol {...col} /></Row>) : "";
        return (
            <div id="personalInformationPage">
                <ArticleHeaderRow {..._data.ArticleHeaderRowData} />
                {hpText}
                <Row>
                    <Col lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
                        <h5>Strivo AB</h5>
                        <p>
                            Att: Strivos dataskyddsombud<br></br>
                            Stora Badhusgatan 18-20<br></br>
                            411 21 Göteborg <br></br>
                            <a className="--text-color-gold" href="mailto:dataskyddsombud@strivo.se">dataskyddsombud@strivo.se</a>
                        </p>
                        <p>
                            För mer information om Strivos hantering av personuppgifter hänvisas till bolagets integritetspolicy som finns tillgänglig via <a href="https://www.strivo.se" target="_blank" rel="noopener noreferrer">www.strivo.se.</a>
                        </p>
                        <p>
                            Ytterligare information om GDPR finns på <a href="https://www.imy.se/" target="_blank" rel="noopener noreferrer">Integritetsskyddsmyndighetens</a> hemsida.
                        </p>
                    </Col>
                </Row>
            </div>
        );
    }
}
