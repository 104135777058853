import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import "./HistoricalReturnPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import { Line } from 'react-chartjs-2';
import "chartjs-plugin-annotation";
import * as _service from "../../performance-helper/PerformanceHelper";

const _data = require('../../app-data/historical-return-page.js');

let state = {
    totalReturn: 0,
    yearlyReturn: 0,
    chartData: {
        labels: [],
        datasets: [
            {
                label: "Fundroid",
                pointRadius: 0,
                pointHoverRadius: 1,
                pointHitRadius: 10,
                pointStyle: 'circle',
                fill: false,
                lineTension: 0.5,
                backgroundColor: '#f29782',
                borderColor: '#f29782',
                borderWidth: 3,
                data: []
            },
            {
                label: "Referensindex",
                pointRadius: 0,
                pointHoverRadius: 1,
                pointHitRadius: 10,
                pointStyle: 'circle',
                fill: false,
                lineTension: 0.5,
                backgroundColor: '#8ec4cb',
                borderColor: '#8ec4cb',
                borderWidth: 3,
                data: []
            }
        ],
        options: {
            tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function (tooltipItem, data) {
                        var datasetLabel = data.datasets[tooltipItem.datasetIndex].label;
                        var label = (tooltipItem.yLabel).toFixed(2);
                        return datasetLabel + ': ' + label;
                    }
                }
            },
            legend: {
                display: false
            },
            annotation: {
                drawTime: 'afterDatasetsDraw',
                annotations: [{
                    drawTime: 'afterDraw',
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    value: '141',
                    borderColor: 'black',
                    borderWidth: 1,
                    borderDash: [2, 2],
                }]
            },
            animation: {
                duration: 0
            },
            hover: {
                animationDuration: 0
            },
            responsiveAnimationDuration: 0,
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    ticks: {
                        maxTicksLimit: 12
                    },
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
    }
};

export class HistoricalReturnPage extends Component {
    static returnIfPositiveNumberText = (number) => number < 0 ? number : "+" + number;
    static returnTwoDecimalNumber = (number) => Math.round(number + Number.EPSILON) * 100 / 100;
    constructor(props) {
        super(props);
        this.state = state;
        this.datasetKeyProvider = this.datasetKeyProvider.bind(this);
    }
    componentDidMount() {
        if (state.chartData.datasets[0].data.length === 0) {
            this.populateData();
        }
    }
    componentWillUnmount() {
        state = this.state;
    }
    datasetKeyProvider() { return Math.random().toString(); }
    render() {
        return (
            <div id="historicalReturnPage">
                <ArticleHeaderRow {..._data.ArticleHeaderRowData} />
                <Row className="justify-content-center">
                    <Col lg={5} xl={4}>
                        <div className="return-card">
                            <div>
                                <h6>Total avkastning*</h6>
                                <h1>{HistoricalReturnPage.returnIfPositiveNumberText(this.state.totalReturn)} %</h1>
                            </div>
                            <img alt="wave.png" src="content/images/historical-return-page/wave.png" />
                        </div>
                    </Col>
                    <Col lg={5} xl={4}>
                        <div className="return-card">
                            <div>
                                <h6>Årlig effektiv avkastning*</h6>
                                <h1>{HistoricalReturnPage.returnIfPositiveNumberText(this.state.yearlyReturn)} %</h1>
                            </div>
                            <img alt="wave.png" src="content/images/historical-return-page/wave.png" />
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} lg={10} xl={8}>
                        <div className="chart-legend-container">
                            <span className="dot"></span><span>Fundroid</span><span className="dot"></span><span>Referensindex</span>
                        </div>
                        <div>
                            <Line
                                data={this.state.chartData}
                                options={this.state.chartData.options}
                                datasetKeyProvider={this.datasetKeyProvider}
                            />
                        </div>
                        <div className="chart-em-container">
                            <em> *Ovanstående historisk avkastning bygger på en simulering under perioden 4 februari 2008 till 1 november 2019
                            som är beräknad netto efter avdrag för fast förvaltningsavgift,
                            prestationsbaserad avgift samt eventuella valutaväxlingsavgifter,
                            i syfte att ge en så rättvisande bild som möjligt.
                            Återläggning av eventuella historiska fondrabatter har inte beaktats i simuleringen,
                            men skulle haft en positiv påverkan på avkastningshistoriken.
                            Observera att historisk utveckling inte är någon garanti för framtida utveckling.
                            Perioden efter den 1 november 2019, markerat med streckad linje i grafen,
                            visar den faktiska avkastningen för Fundroids modellportfölj.
                            Referensindex utgörs av 75% Morningstar Global Markets Index (SEK) +25% OMRX T-Bond.
                                  Källa: Bloomberg och Strivo.</em>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    getCagr(lastIndex, firstIndex, numberOfYears) {
        return Math.round(((Math.pow((lastIndex / firstIndex), (1 / numberOfYears)) - 1) * 100 +
                    (Number.EPSILON || Math.pow(2, -52))) *
                100) /
            100;
    }

    async populateData() {
        const simDataFundroid = _data.DataPoints.fundroid;
        const simDataBenchMark = _data.DataPoints.benchmark;
        const dataPoints = await _service.getPerformanceData('account/get-compare-performance');

        this.setState((prevState) => {
            if (dataPoints) {
                const fundroidPoints = dataPoints.fundroidPerformances;

                const newState = { ...prevState };

                simDataFundroid.forEach(item => {
                    newState.chartData.datasets[0].data.push({ x: item.date, y: item.point });
                    newState.chartData.labels.push(item.date);
                });

                simDataBenchMark.forEach(item => {
                    newState.chartData.datasets[1].data.push({ x: item.data, y: item.point });
                });

                fundroidPoints.forEach(item => {
                    newState.chartData.datasets[0].data.push({ x: item.date, y: item.cumulativePerformance });
                    newState.chartData.labels.push(item.cSimpleDateString);
                    newState.chartData.datasets[1].data.push({ x: item.date, y: item.cumulativeBenchmarkPerformance });
                });

                const firstIdx = simDataFundroid[0].point;
                const lastIdx = fundroidPoints[fundroidPoints.length - 1].cumulativePerformance;
                const simYears = parseFloat(simDataFundroid.length / 12 + (fundroidPoints.length / 12));

                newState.yearlyReturn = this.getCagr(lastIdx, firstIdx, simYears);
                newState.totalReturn = dataPoints.totalReturn;

                return newState;
            }
            return {};
        });

    }
}
