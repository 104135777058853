export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/legal-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/puzzled.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Synpunkter och klagomål",
    }
};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },   
    bodyText: {
        paragraphs: ["Det viktigaste för oss är att du som kund ska känna dig nöjd med oss. Skulle du ändå inte vara nöjd ombeds du följa instruktionerna nedan för att reklamera eller klaga på något."]
    }
},
{
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Reklamation",
    bodyText: { paragraphs: ["Om du skulle känna dig missnöjd med något är du alltid välkommen att framför dina synpunkter till Strivo. Ofta kan det handla om ett missförstånd eller att fakta saknas. Det är viktigt att du som kund tar kontakt med oss snarast möjligt om något inte stämmer. Det ökar möjligheterna att hitta en gemensam lösning på problemet."] }
},
{
    id: 3,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Klagomål",
    bodyText: { paragraphs: ["Om du som kund efter detta ändå inte känner dig nöjd kan du kontakta: Klagomålsansvarig, Strivo, Stora Badhusgatan 18-20, 411 21 Göteborg. Klagomålet ska framföras skriftligen. Handläggningen underlättas om du som kund bifogar din dokumentation av ärendet i form av kopia på anmälningssedel, avräkningsnota, depå-/värderingsrapporter, minnesanteckningar etc. Klagomålsansvarig hos Strivo är CCO Richard Sundh."] }
},
{
    id: 4,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Konsumenternas Bankbyrå",
    bodyText: { paragraphs: ["Ibland kan man vilja vända sig till någon utomstående part. Om du önskar diskutera ditt ärende med någon utomstående är du välkommen att ta kontakt med Konsumenternas Bank- och finansbyrå. {a}"] },
    textLink: {
        linkUrl: "https://www.konsumenternas.se",
        innerText: "www.konsumenternas.se",
        linkStyle: { color: "#d5b266" },
        external: true
    },
},
{
    id: 5,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    colStyles: {marginBottom: 0},
    header: "Kommunala konsumentvägledare",
    bodyText: { paragraphs: ["Som kund kan du också vända dig till den kommunala konsumentvägledaren i din kommun. Konsumentverket svarar inte på enskilda frågor och ingriper inte i enskilda tvister, men kommunala konsumentvägledare arbetar i nära kontakt med Konsumentverket och kan ge värdefulla råd i enskilda tvister och ärenden. Telefonnummer och e-postadress till lokala vägledare går att finna på deras hemsida. {a}"] },
    textLink: {
        linkUrl: "https://www.konsumentverket.se",
        innerText: "www.konsumentverket.se",
        linkStyle: { color: "#d5b266" },
        external: true
    },
},
{
    id: 6,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    colStyles: {marginBottom: 0},
    header: "Allmänna reklamationsnämnden",
    bodyText: { paragraphs: ["Skulle ditt klagomålsärende fortfarande inte resultera i ett tillfredsställande resultat har du möjlighet att göra en skriftlig anmälan till Allmänna Reklamationsnämnden (ARN). För att ARN ska kunna ta upp ärendet krävs det att ditt krav överstiger ett visst minimibelopp. En anmälan till ARN skall ske inom sex månader från det att Strivo första gången helt eller delvis avvisat ditt anspråk. Från ARN ges rekommendationer om hur tvisten mellan dig och Strivo bör lösas. {a}"] },
    textLink: {
        linkUrl: "https://www.arn.se/",
        innerText: "www.arn.se",
        linkStyle: { color: "#d5b266" },
        external: true
    },
},
{
    id: 7,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    colStyles: {marginBottom: 0},
    header: "Prövning i domstol",
    bodyText: { paragraphs: ["Slutligen kan du även vända dig till domstol med en stämningsansökan mot Strivo. I ett sådant läge bör du anlita ett ombud som kan bistå med nödvändig hjälp i processen samt göra en bedömning av ärendet och utsikterna för framgång i tvisten."] }
}];

