export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/legal-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/document.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Användarvillkor",
    }
};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "1. Allmänt",
    bodyText: {
        paragraphs: ['Denna webbplats (www.fundroid.se) tillhör Strivo AB, 556759-1721, ("Strivo"). Genom att använda eller ladda ned information från Webbplatsen förbinder du dig att följa dessa villkor för användande ("Villkoren"). Vissa sidor och dokument på Webbplatsen kan omfatta ytterligare eller alternativa villkor och regler. För produkter och tjänster som tillhandhålls på Webbplatsen finns dessutom separata villkor och regler. Om du inte accepterar dessa Villkor eller andra villkor eller regler som gäller för Webbplatsen, får du inte använda Webbplatsen och inte heller ladda ner någon information från Webbplatsen. Strivo förbehåller sig rätten att ändra Villkoren. Sådan ändring kan göras när som helst och utan föregående avisering. Villkoren bör därför läsas igenom regelbundet. Strivo förbehåller sig rätten att efter eget gottfinnande uppdatera Webbplatsen eller avbryta eller på annat sätt begränsa användandet av den.']
    }
},
{
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "2. Personer utanför Sverige/People outside of Sweden",
    bodyText: {
        paragraphs: ["Webbplatsen vänder sig inte till personer som är förhindrade att ha tillgång till en sådan webbplats enligt gällande lagstiftning i de länder som de är medborgare eller bosatta. Om du är förhindrad att ta del av materialet på Webbplatsen eller om du har något tvivel huruvida du skulle vara förhindrad att ta del av materialet på Webbplatsen får du inte använda den. I sådant fall ber vi dig att omgående lämna Webbplatsen. Mer {a}"
        ]
    },
    textLink: {
        linkUrl: "https://strivo.se/important-information-for-people-outside-sweden/",
        innerText: "information",
        linkStyle: { color: "#d5b266" },
        external: true
    },
},
{
    id: 3,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    colStyles : {marginTop: "-2rem"},
    bodyText: {
        paragraphs: ["This Website is not directed at and is not intended for persons who are prohibited under the applicable laws or regulations of their citizenship, domicile or residence from having access to or use the Website. Persons who are prohibited from having access to the information on this Website or who are in doubt whether they are permitted to access or use the information or not, are requested to leave the Website. More {a}"
        ]
    },
    textLink: {
        linkUrl: "https://strivo.se/important-information-for-people-outside-sweden/",
        innerText: "information",
        linkStyle: { color: "#d5b266" },
        external: true
    },
},
{
    id: 4,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "3. Varumärken och upphovsrätt",
    bodyText: { paragraphs: ["Alla rättigheter till varumärken och kännetecken på Webbplatsen tillhör Strivo, dess licensgivare eller andra samarbetsparter. Varumärken och kännetecken tillhörande Strivo får inte användas utan Strivos skriftliga tillåtelse. Allt innehåll på Webbplatsen såsom text, grafik, märken, funktionsikoner, bilder, ljudklipp och mjukvara innehas av Strivo med äganderätt eller har licensierats för användande av Strivo och skyddas av upphovsrättslagen eller motsvarande internationell lagstiftning. Det innebär bl.a. att allt återgivande av material eller delar därav förutsätter upphovsmannens tillstånd."] }
},
{
    id: 4,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "4. Ansvarsbegränsningar",
    bodyText: {
        paragraphs: ["Informationen som presenteras på denna webbsida (www.fundroid.se) utgör endast marknadsföring och ger inte en komplett bild av Tjänsten. Informationen är endast avsedd som allmän information och utgör inte i något fall rådgivning, rekommendationer eller vägledning vid beslut om investeringar. Informationen på Webbplatsen är baserad på källor som Strivo bedömt vara tillförlitliga men Strivo ansvarar inte för eventuella fel i informationen som presenteras på www.fundroid.se eller förluster som uppkommer genom att någon följer vad som sagts i denna webbplats eller använder sig av uppgifter lämnade däri. Det ankommer på användaren av Webbplatsen att själv värdera lämpligheten och användbarheten av de tjänster som beskrivs eller tillhandahålls på Webbplatsen, samt den information som förmedlas på Webbplatsen. Användare av Webbplatsen bör alltid konsultera rådgivare vad gäller just deras personliga förhållanden.  ",
            "Att investera i fonder och andra finansiella instrument innebär ett risktagande. Värdet och avkastningen på en investering kan såväl öka som minska och hela eller delar av det investerade kapitalet kan gå förlorat. Det är således inte säkert att du får tillbaka det investerade kapitalet. Historisk avkastning och historiskt resultat är inte någon garanti för framtida avkastning och resultat.",
            "Det finns en risk att informationen inte är fullständig, uttömmande eller uppdaterad. Det finns också en risk att informationen är felaktig eller innehåller tekniska brister. Användandet av informationen på Webbplatsen sker således på egen risk och användare av Webbplatsen rekommenderas att använda lämpliga anti-virusprogram och annan säkerhetsmjukvara. I den mån Webbplatsen innehåller länkar till andra hemsidor kan Strivo inte garantera åtkomst till dessa och Strivo frånsäger sig allt ansvar för innehållet på sådana sidor. Information som skickas via Webbplatsen eller e-post sker på egen risk. Strivo ansvarar inte i något fall för skada i någon form som direkt eller indirekt orsakats av, eller på något sätt relaterar till, informationen på Webbplatsen eller användandet eller bristande förmåga att använda Webbplatsen.",
            "Strivo är i utförandet av tjänsten beroende av flera externa system och tjänsteleverantörer avseende exempelvis banktjänster, betalningslösningar, kursinformation, orderläggning,, förvaring av finansiella instrument och kontanter med mera. Bolaget ska inte hållas ansvarig för skador som direkt eller indirekt beror på fel eller tillfälliga driftstörningar i något av de externa system som Bolaget använder sig av. För att använda onlinetjänsterna på www.fundroid.se ska du vara 18 år fyllda och ha tillgång till Mobilt BankID för säker identifiering."]
    }
},
{
    id: 5,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "5. Personuppgifter",
    textLink: {
        linkUrl: "https://strivo.se/behandling-av-personuppgifter/",
        innerText: "här",
        linkStyle: { color: "#d5b266" },
        external: true
    },
    bodyText: { paragraphs: ["Strivo är personuppgiftsansvarig för behandlingen av personuppgifter på Webbplatsen. Information om Strivos behandling av personuppgifter finner du {a}."] }
},
{
    id: 6,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "6. Cookies",
    textLink: {
        linkUrl: "/cookies",
        innerText: "här",
        linkStyle: { color: "#d5b266" },
        external: false
    },
    bodyText: {
        paragraphs: ["Enligt lagen om elektronisk kommunikation ska alla som besöker en webbplats med cookies få information om att webbplatsen använder cookies och vad de används för. Information om cookies på Webbplatsen finner du {a}.",
            "Besökaren ska också samtycka till att cookies används. Samtycke till att cookies används lämnas genom att du använder Webbplatsen och att du accepterar cookies i webbläsaren. Om du inte samtycker till cookies kan funktionaliteten på Webbplatsen begränsas."]
    }
},
{
    id: 7,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "7. Tillämplig lag m.m.",
    bodyText: { paragraphs: ["Svensk lag är tillämplig på information som lämnas på Webbplatsen och på avtal träffade genom Webbplatsen och på Webbplatsen i övrigt. Alla tvister hänförliga härtill skall slutligen avgöras av svensk domstol."] }
}
];
