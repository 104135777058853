export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/about-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/shield-protection.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Insättningsgaranti & Investerarskydd",
    }
};

export const ArticleBodyRowData = {
    backgroundImagePath: "url(content/images/monthly-activity-page/body-bg.png)",
};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },    
    bodyText: {
        paragraphs: ["Strivo omfattas inte av den s.k insättningsgarantin. Strivo omfattas av något som kallas investerarskydd. SI har ett tillstånd från Finansinspektionen ”att ta emot kontanta medel med redovisningsskyldighet”. Det innebär att Strivo håller samtliga kunders medel separerade från sina egna. Om det osannolika skulle hända att Strivo går i konkurs så är samtliga kunders tillgångar (värdepapper och likvida medel) skyddade. Det finns alltså ingen övre gräns, som Insättningsgarantin, om € 100.000. Om det vid en konkurs ändå skulle framkomma att Strivo på något sätt blandat ihop kundens tillgångar med sina egna (vilket i praktiken för Strivo är högst osannolikt) finns det ett skydd om 250.000 kr att utkräva via Riksgälden."
        ]
    }
}];
