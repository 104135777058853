export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/legal-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/privacy.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Hantering av personuppgifter",
    }
};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    bodyText: {
        paragraphs: ['Hantering av personuppgifter sker i enlighet med dataskyddsförordningen (GDPR, EU 2016/679). Förordningen syftar till att skydda den enskildes integritet. Strivo AB, 556759–1721, ("Strivo"), som är personuppgiftsansvarig, är därför skyldig att behandla dina personuppgifter enligt förordningens regler.']
    }
},
{
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "För Strivo innebär dessa regler i korthet följande:",
},
{
    id: 3,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Syfte",
    headerStyles: { fontSize: "1.25rem" },
    bodyText: { paragraphs: ["Till vad ska Strivo använda dina personuppgifter? Syftet måste vara tydligt och får inte ändras i efterhand."] }
},
{
    id: 4,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Relevans",
    headerStyles: { fontSize: "1.25rem" },
    bodyText: { paragraphs: ["Strivo kommer bara samla in personuppgifter som är relevanta för att uppnå det syfte och ändamål som de samlats in för. Insamling av uppgifter som kan vara ”bra att ha i framtiden” eller ”för säkerhets skull” kommer inte att ske."] }
},
{
    id: 6,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Radera",
    headerStyles: { fontSize: "1.25rem" },
    bodyText: { paragraphs: ["Strivo kommer inte lagra information längre än nödvändigt. Så fort Strivo inte längre har behov av dina personuppgifter kommer de att raderas."] }
},
{
    id: 7,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Tillgång",
    headerStyles: { fontSize: "1.25rem" },
    bodyText: { paragraphs: ["Endast de medarbetare som behöver personuppgifter för att kunna utföra sitt arbete kommer ha tillgång till uppgifterna."] }
},
{
    id: 8,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Skydd",
    headerStyles: { fontSize: "1.25rem" },
    bodyText: { paragraphs: ["Personuppgifter ska skyddas. Strivo hanterar personuppgifter på ett sätt som minimerar risken att de kommer visas för obehöriga."] }
},
{
    id: 9,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Medgivande/samtycke",
    headerStyles: { fontSize: "1.25rem" },
    bodyText: { paragraphs: ["Strivo kommer bara att behandla dina personuppgifter efter att du aktivt samtyckt till behandlingen."] }
},
{
    id: 10,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Vetskap",
    headerStyles: { fontSize: "1.25rem" },
    bodyText: { paragraphs: ["Kunder vars personuppgifter Strivo hanterar, ska veta hur och till vad deras uppgifter används. Informationen ska finnas lättillgänglig och vara lätt att förstå. När som helst ska en kund kunna återkalla sitt medgivande till Strivo."] }
},
{
    id: 11,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Myndighetskontakter",
    headerStyles: { fontSize: "1.25rem" },
    bodyText: {
        paragraphs: ["I vissa fall är Strivo skyldig att lämna uppgifter till myndighet, till exempel till Skatteverket, Finansinspektionen och Integritetsskyddsmyndigheten.",
            "I huvudsak består Strivo verksamhet av att producera och distribuera finansiella produkter. Det innebär att det är Strivo som fullgör alla tjänster i samband med emission och ingår avtal med och agerar motpart i förhållande till banker och förmedlarbolag.",
            "Verksamheten omfattar även att tillhandahålla andra tjänster såsom depåer, ISK-konton, fondhandel etc. De personuppgifter som Strivo behandlar sker därför inom ramen för den administration som sker av finansiella produkter och andra tjänster samt för att kunna uppfylla gällande lagstiftning exempelvis beträffande lag om värdepappersmarknaden, bokföringslagen och lag om åtgärder mot penningtvätt och finansiering av terrorism, samt för att kunna hantera och bemöta eventuella kundklagomål.",
            "Enligt förordningen har varje registrerad individ rätt att få information om och rättelse av de personuppgifter som registrerats. Sådan begäran framställs skriftligen till:"]
    }
}];
