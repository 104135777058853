import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import "./AccordionRow.scss"

export default class AccordionRow extends Component {
    constructor(props) {
        super(props);
        this.tooggleAccordion = this.tooggleAccordion.bind(this);
        this.state = {
            collapsed: true,
        };
    }
    tooggleAccordion() {
        this.setState({ collapsed: !this.state.collapsed });
    }
    render() {
        let link = "";
        if (this.props.textLink) {
            link = <a href={this.props.textLink.linkUrl} style={this.props.textLink.linkStyle}
                rel="noopener noreferrer" target="_blank">{this.props.textLink.innerText}</a>
        }
        const QUESTION_CLASSES = this.state.collapsed ? "accordion-question-col" : "accordion-question-col expanded";
        let formattedText = "";
        if (this.props.answer && this.props.answer.paragraphs) {
            formattedText = this.props.answer.paragraphs.map(p => {
                if (p.indexOf("{a}") > 0) {
                    let textBeforeLink = p.slice(0, p.indexOf("{a}"));
                    let textAfterLink = p.slice(p.indexOf("{a}") + 3, p.length);
                    return <p key={p}>{textBeforeLink}{link}{textAfterLink}</p>
                }
                else {
                    return <p key={p}>{p}</p>
                }
            });
        }     
        return (
            <Row className="accordion-row">
                <Col xs={12} xl={10}>
                    <Row onClick={this.tooggleAccordion}>
                        <Col className={QUESTION_CLASSES} xs={12} xl={11}>
                            <h5>{this.props.question}</h5>
                            <span className="plus alt"></span>
                        </Col>
                    </Row>
                    <Row hidden={this.state.collapsed} className="accordion-answer-row">
                        <Col xs={12} xl={9}>
                            {formattedText}
                        </Col>
                    </Row>
                </Col>
            </Row>

        );
    }



}
