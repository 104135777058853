import React from 'react';
import { Row, Col } from 'reactstrap';
import "./ArticleBodyRow.scss";
import HPTextCol from '../hp-text-col/HPTextCol';

const ArticleBodyRow = (props) => {
    return (        
        <Row className="article-body-row">
            <Col xs={12} >
                <Row style={{ backgroundImage: props.backgroundImagePath }} className="article-body-background-row">
                    <div className="overlay"></div>
                </Row>
                <Row>
                   <HPTextCol {...props.hpTextColData} />
                </Row>
            </Col>

        </Row>
    )
}
export default ArticleBodyRow;