export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/legal-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/financial-data.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Pensionssparande i depåförsäkring",
    }
};

export const ArticleBodyRowData = {
    backgroundImagePath: "url(content/images/private-savings-page/stock.png)",   
    header: "Depåförsäkring",
    bodyText: {
        paragraphs: ["En depåförsäkring erbjuder dig som privatperson eller företag en möjlighet att handla med värdepapper utan att behöva deklarera köp och försäljningar och utan att behöva betala skatt på vinster och utdelningar. Skillnaden mellan en fondförsäkring och en depåförsäkring är du i en depåförsäkring inte enbart är begränsad till fonder utan du kan också investera i andra typer av värdepapper. Du som privatperson kan t.ex. spara i en kapitalförsäkring (K) eller tjänstepensionsförsäkring (T). Även för ditt företag kan en kapitalförsäkring (K) vara en enkel och smidig lösning.",
            "För dig som vill spara med Fundroid erbjuder vi en särskild depåförsäkring med Strivo som depåinstitut i samarbete med Futur Pension (tidigare Danica Pension) som är försäkringsbolag. Depåförsäkringen är en s.k. nettohandlad depåförsäkring, vilket innebär att de fonder som förvaras i depåförsäkringen handlas till nettopris. Det betyder i praktiken en rabatt på fondens ordinarie förvaltningsavgift för dig som kund. Detta kan vi uppnå genom att vi återbetalar alla fondprovisioner eller andra typer av tredjepartsersättningar som fondbolagen normalt sett betalar till depåinstitut, banker, nätmäklare eller fonddistributörer. Dessa rabatter ligger normalt på cirka 50 procent av fondens förvaltningsavgift, vilket innebär att du genom Fundroid får några av marknadens bästa fonder till i genomsnitt halva priset. Och med en fondrobot som aktivt förvaltar fondportföljen åt dig."]
    }

};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    bodyText: { paragraphs: ["De flesta svenskar har pension från sin arbetsgivare, så kallad tjänstepension. Detta är ett viktigt komplement till den allmänna pensionen för att säkerställa att skillnaden mellan din lön under arbetslivet och din framtida pension inte blir alltför stor. Tjänstepensionen regleras antingen genom kollektivavtal eller genom ett avtal mellan dig som anställd och din arbetsgivare.",
"Hur mycket du får i pension beror på ett flertal olika faktorer, bl.a. hur många år du arbetar och din pensionsgrundande inkomst. Den beror också på yttre faktorer som den ekonomiska utvecklingen i omvärlden, den generella löneutvecklingen i samhället, en ökad medellivslängd samt avkastningen på din pension. Andra faktorer som kan påverka är hur du väljer att placera och förvalta kapitalet i din pensionsförsäkring, vilka avgifter som är kopplade till försäkringen, m.m."] },
},
{
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    bodyText: { paragraphs: ["För många av oss kan det vara svårt att, åtminstone under vissa perioder av livet, avsätta tiden till eller intresset för att aktivt och löpande se över och förvalta sitt pensionskapital under alla de år som är kvar till pensionen. Då kan Fundroid vara ett bra alternativ. Genom Fundroid får du en sparrobot som aktivt ser över ditt pensionssparande månader efter månad, år efter år, och gör de fondbyten som behövs för att du med gott samvete ska hinna tänka på annat."] },
}];
