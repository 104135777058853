
export async function getPerformanceData(path) {
    try {
        const response = await fetch(path);
        if (!response) throw Error;

        const performances = await response.json();
        return performances;
    } catch (e) {
        console.log(e);
    }
}
