import React from 'react';
import "./HPTextCol.scss";
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';

const HPTextCol = (props) => {
    const NAVIGATIONBUTTON = props.navigationButton ? <Link to={props.navigationButton.linkUrl ? props.navigationButton.linkUrl : "/"}> <button style={props.navigationButton.customButtonStyle}>{props.navigationButton.buttonText}</button></Link> : "";
    let link = "";
    const HEADER = props.header ? <h4 style={props.headerStyles}>{props.header}</h4> : "";
    const COOKIETEXT = props.cookieName && props.cookieDomain ? <p className="cookie-type-text">Namn: <span>{props.cookieName}</span><br></br>Domän: <span>{props.cookieDomain}</span></p> : "";
    if (props.textLink) {
        if (props.textLink.toMail) {
            link = props.textLink.external ? <a href={props.textLink.linkUrl} style={props.textLink.linkStyle}>{props.textLink.innerText}</a> : <Link to={props.textLink.linkUrl} style={props.textLink.linkStyle}>{props.textLink.innerText}</Link>;
        }
        else {
            link = props.textLink.external ? <a href={props.textLink.linkUrl} style={props.textLink.linkStyle} rel="noopener noreferrer" target="_blank">{props.textLink.innerText}</a> : <Link to={props.textLink.linkUrl} style={props.textLink.linkStyle}>{props.textLink.innerText}</Link>;
        }
    }
    let formattedText = "";
    if (props.bodyText && props.bodyText.paragraphs) {
        formattedText = props.bodyText.paragraphs.map((p, i) => {
            if (p.indexOf("{a}") > 0) {
                let textBeforeLink = p.slice(0, p.indexOf("{a}"));
                let textAfterLink = p.slice(p.indexOf("{a}") + 3, p.length);
                return <p key={p}>{textBeforeLink}{link}{textAfterLink}</p>
            }
            else {
                return <p key={p}>{p}</p>
            }
        });
    }
    return (
        <Col className="hp-text-col" style={props.colStyles} xs={props.colXsSize} lg={props.colLgSize} xl={props.colXlSize}>
            {HEADER}
            {COOKIETEXT}
            {formattedText}
            {NAVIGATIONBUTTON}
        </Col>
    )
}
export default HPTextCol;
