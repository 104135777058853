export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/legal-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/loan.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Så sparar du i Fundroid",
    }
};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Spara lite eller mycket, ofta eller sällan – helt utan bindningstid!",
    bodyText: {        
        paragraphs: ["Du väljer själv om du vill investera en klumpsumma och/eller om du vill månadsspara via autogiro från externt bankkonto. Fundroid passar lika bra för dig som vill åt en aktiv förvaltning av ditt pensionskapital i en försäkringsdepå, som för ett vanligt buffertsparande på ditt investeringssparkonto (ISK). Du kan självklart närsomhelst göra uttag från ditt investeringssparkonto eller helt avsluta förvaltningen."]
    }
},
{
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Rapportering",
    textLink: {        
        linkUrl: "/",
        innerText: "www.fundroid.se",
        linkStyle: { color: "#d5b266" },
        external: false        
    },
    bodyText: {        
        paragraphs: ["Som kund har du alltid full kontroll och insyn i förvaltningen genom en enkel och smidig inloggning på {a} där du kan följa avkastningen i fondportföljen och läsa den senaste förvaltningsrapporten, som publiceras kvartalsvis."]
    }
},
{
    id: 3,
    colXlSize: { size: 8, offset: 2 },
    colLgSize: { size: 10, offset: 1 },
    header: "Möjlighet till personlig service",
    bodyText: { paragraphs: ["För dig med ett större sparkapital erbjuder vi möjligheten att prata med en fristående lokal rådgivare på din ort. Den valfria rådgivningen, som sker helt fristående från oss, kan hjälpa dig att få en bättre överblick över din ekonomi och ditt pensionssparande, samt diskutera dina behov och sparmål."] }
}];
