import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './ContactCard.scss';

export default class ContactCard extends Component {  
  
    render() {
        return (
            <Row className="contact-card">
                <Col xs={12}>
                    <Row className="contact-card-city-menu">
                        <Col onClick={this.props.handler} className={this.props.activeCity === "goteborg" ? "active" : ""} xs={6}><h5>Göteborg</h5></Col>
                        <Col onClick={this.props.handler} className={this.props.activeCity === "stockholm" ? "active" : ""} xs={6}><h5>Stockholm</h5></Col>
                    </Row>
                    <Row className="contact-card-city-content">
                        <Col xs={6} xl={3}>
                            <img alt="pin.png" className="img-fluid" src="content/images/contact-page/pin.png"></img>
                            <h6>Adress</h6>
                            <p>
                                Strivo AB<br></br>
                                {this.props.adress.street}<br></br>
                                {this.props.adress.postNrCity}
                            </p>
                        </Col>
                        <Col xs={6} xl={3}>
                            <img alt="phone.png" className="img-fluid" src="content/images/contact-page/phone.png"></img>
                            <h6>Telefon</h6>
                            <p><a className="--text-color-gold" href={`tel: ${this.props.phone}`}>{this.props.phone}</a></p>
                        </Col>
                        <Col xs={6} xl={3}>
                            <img alt="email.png" className="img-fluid" src="content/images/contact-page/email.png"></img>
                            <h6>Email</h6>
                            <p><a className="--text-color-gold" href="mailto:info@strivo.se">info@strivo.se</a></p>
                        </Col>
                        <Col xs={6} xl={3}>
                            <img alt="fax.png" className="img-fluid" src="content/images/contact-page/fax.png"></img>
                            <h6>Fax</h6>
                            <p><a className="--text-color-gold" href={`fax: ${this.props.fax}`}>{this.props.fax}</a></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }



}
