import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './NotFoundPage.scss'
import { Link } from 'react-router-dom';

export class NotFoundPage extends Component {
    render() {
        return (
            <div id="notFoundPage">
                <Row>
                    <Col lg={{size:8, offset:2}}>
                    <h1>Vi hittade inte sidan :(</h1>
                    <p>Vi kanske har tagit bort eller flyttat den.<br></br>
                    Felet kan också bero på att du skrivit in fel adress i webbläsaren eller använder ett gammalt bokmärke.</p>
                    <p>Ta mig tillbaka till <Link to="/">förstasidan</Link></p>
                    </Col>
                </Row>
            </div>
        );
    }
}

