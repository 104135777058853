import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "./NavMenu.scss"

export default class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    closeNavbar() {
        if (this.state.collapsed !== true) {
        this.toggleNavbar();
        }
    }
    render() {
        return (
            <header>
                <Navbar className="navbar-expand-lg navbar-toggleable-lg ng-white box-shadow mb-3" light>
                    <Container fluid={true}>
                        <NavbarBrand tag={Link} to="/"><img height="30" src="content/images/nav-menu/logo.svg" alt="fundroid.logo" /></NavbarBrand>
                        <NavbarToggler id="navToggler" onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-lg-inline-flex flex-lg-row-reverse"  isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={RRNavLink} onClick={this.closeNavbar} activeClassName="active-link" className="text-dark" to="/om-fundroid">Detta är Fundroid</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RRNavLink} onClick={this.closeNavbar} activeClassName="active-link" className="text-dark" to="/fragor-och-svar">Vanliga frågor</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RRNavLink} onClick={this.closeNavbar} activeClassName="active-link" className="text-dark" to="/kontakta-oss">Kontakta oss</NavLink>
                                </NavItem>
                                <NavItem>
                                    <a href="https://online.strivo.se" target="_blank" rel="noreferrer noopener"><button className="primary">Logga in</button></a>
                                </NavItem>
                                <NavItem>
                                    <Link to="/borja-spara"><button onClick={this.closeNavbar} className="outline-primary">Börja spara</button></Link>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
