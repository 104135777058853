import React, { Component } from 'react';
import { Row } from "reactstrap";
import "./ComplaintsPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import HPTextCol from '../../components/presentational/hp-text-col/HPTextCol';

const _data = require('../../app-data/complaints-page.js');

export class ComplaintsPage extends Component {

    render() {
        const hpText = _data.HPTextColData ? _data.HPTextColData.map(col => <Row key={col.id}><HPTextCol {...col} /></Row>) : "";        
        return (
            <div id="complaintsPage">
                <ArticleHeaderRow {..._data.ArticleHeaderRowData} />
                {hpText}                  
            </div>
        );
    }
}
