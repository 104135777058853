/* eslint-disable */
import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import "./CookiesPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import HPTextCol from '../../components/presentational/hp-text-col/HPTextCol';


const _data = require('../../app-data/cookies-page.js');

export class CookiesPage extends Component {
    handleCookies(){
        klaro.show();
        return false;
    };
constructor(props){
    super(props);
    this.handleCookies = this.handleCookies.bind(this);
}
    render() {
        const hpText = _data.HPTextColData ? _data.HPTextColData.map(col => <Row key={col.id}><HPTextCol {...col} /></Row>) : "";
        return (
            <div id="cookiesPage">
                <ArticleHeaderRow {..._data.ArticleHeaderRowData} />
                {hpText}  
                <Row>
                <Col xl={{size: 8, offset: 2 }} lg={{ size: 10, offset: 1 }}><span style={{cursor:"pointer"}} className="fake-link" onClick={this.handleCookies}>Hantera cookies</span></Col>  
                </Row>        
            </div>
        );
    }
}
