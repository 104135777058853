import React from 'react';
import "./ListUl.scss";

const ListUl = (props) => {    
const listItems = props.listItems ? props.listItems.map(li => <li key={li}>{li}</li>) : "";
    return (       
        <ul className="list-ul" style={props.ulStyles}>            
                {listItems}            
        </ul>
    )
}
export default ListUl;
