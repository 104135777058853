import React from 'react';
import { GoogleMap, Marker, withScriptjs, withGoogleMap } from 'react-google-maps';
import mapStyling from './mapStyles'
const defaultMapOptions = {
    styles: mapStyling,
    fullscreenControl: false,
    streetViewControl:false,
    mapTypeControl: false, 
    scrollwheel: false,   
  };
function Map (props) {
    return (
        <GoogleMap defaultZoom={18}
            defaultCenter={props.defaultCenter}
            center = {props.defaultCenter}
            defaultOptions={defaultMapOptions}
          >
                <Marker position={props.defaultCenter}
                icon={{url:"content/images/contact-page/map-pin.png"}}                
                 />
            </GoogleMap>
    );
}

const MapContainer = withScriptjs(withGoogleMap(Map));

export default MapContainer;

