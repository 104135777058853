import React from 'react';
import { Row, Col } from 'reactstrap';
import "./ArticleHeaderRow.scss";
import HpTextCol from "../hp-text-col/HPTextCol";

const ArticleHeaderRow = (props) => {
    const ICONROW = props.iconInnerImageSrc ?
        <Row>
            <div className="article-header-icon-container">
                <img src={props.iconImageSrc} className="img-fluid" alt="content.png" />
                <img src={props.iconInnerImageSrc} className="img-fluid" height="45" width="45" alt="content.png" />
            </div>
        </Row> : "";
    return (
        <Row className="article-header-row" style={{ backgroundImage: props.backgroundImagePath }}>
            <Col xs={12} >
                <Row className="">
                    <HpTextCol {...props.hPTextColData} />
                </Row>
            </Col>
            <Col>
                {ICONROW}
            </Col>
        </Row>
    )
}
export default ArticleHeaderRow;