export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/about-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/question-answer.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Frågor & Svar",
        bodyText: { paragraphs: ["Här finner du svaren på några av de vanligaste frågorna som vi får. Om du inte hittar svaret på just din fråga så får du gärna {a}"] },
        textLink: {
            external:true,
            toMail:true,
            linkUrl: "mailto:kundsupport@strivo.se",
            innerText: "kontakta oss",
            linkStyle: { color: "#89c5cd" }
        }
    }
};

export const AccordionRowData = [{
    question: "Vad är Fundroid?",
    answer: {
        paragraphs: ["Fundroid är en intelligent fondrobot vars förvaltningsmodell utgår från en på förhand programmerad algoritm. Algoritmen utnyttjar statistiska och matematiska modeller applicerade på digitalt lagrad information avseende kvalitativa egenskaper avseende de fonder som ingår i Fundroids urval, varigenom Fundroid filtrerar fram de fonder som historiskt uppvisat bäst riskjusterad avkastning över en längre tidsperiod. Utifrån detta urval föreslår modellen en portföljallokering i de fonder som i det korta perspektivet har genererat högst historisk absolutavkastning. Förvaltningsmodellens syfte är att hela tiden investera i de just nu avkastningsmässigt bästa fonderna, av de fonder som över en betydligt längre tidsperiod har uppvisat de bästa kvalitativa egenskaperna på fondmarknaden. Analysen görs om automatiskt varannan månad varpå Fundroid ser till att göra en omallokering av din portfölj."
        ]
    }
},
{
    question: "Vad investerar Fundroid i?",
    answer: {
        paragraphs: ["Fundroid investerar uteslutande i värdepappersfonder. Under kortare perioder, t.ex. när fondbyten genomförs, kan en mindre del av det investerade kapitalet dock vara placerat i kontanta medel. Fundroid investerar inte i specialfonder eller AIF-fonder."
        ]
    }
},
{
    question: "Vad är en värdepappersfond?",
    answer: {
        paragraphs: ["Med värdepappersfonder avses fonder som förvaltas enligt EU-direktivet UCITS (”Undertakings for Collective Investment in Transferable Securities”). En värdepappersfond är en portfölj av minst 16 olika värdepapper som förvaltas av ett fondbolag, med en särskild inriktning. Det finns olika kategorier av fonder baserat på vad de innehåller för värdepapper, såsom aktiefonder, räntefonder, blandfonder och råvarufonder."
        ]
    }
},
{
    question: "Är Fundroid en fond?",
    answer: {
        paragraphs: ["Nej, Fundroid är inte en fond utan en diskretionär portföljförvaltningstjänst som utförs av en fondrobot (Fundroid) och som i sin tur investerar i fonder för din räkning på det investeringssparkonto eller den försäkringsdepå som du har valt att öppna för ditt Fundroid-sparande."
        ]
    }
},
{
    question: "På vilken typ av konto kan jag spara i Fundroid?",
    answer: {
        paragraphs: ["Fundroid erbjuds i dagsläget endast inom ramen för ett investeringssparkonto (ISK) hos Strivo (för privatpersoner), alternativt en depåförsäkring hos Strivo (för privatpersoner och företag). Båda alternativen är självklart ”nettohandlade”."
        ]
    }
},
{
    question: "Vad innebär det att en fond eller en depå är ”nettohandlad”?",
    answer: {
        paragraphs: ["När du vanligtvis investerar i en värdepappersfond i Sverige betalas ungefär hälften av fondens förvaltningsavgift från fondbolaget till den som gjort det möjligt för dig att äga fonden, t.ex. en bank, en nätmäklare eller ett pensionsbolag. Denna ersättning utgör en form av ”säljprovision” (även kallad distributionskostnad, tredjepartsersättning eller ”kickback”). En nettohandlad fond är en fond där denna ersättning istället betalas tillbaka till dig som kund, vilket sker automatiskt till den depåtyp du har valt för ditt Fundroid-sparande. Det innebär i praktiken en rabatt på i genomsnitt cirka femtio procent i förhållande till ordinarie pris för motsvarande fond. Över tid kan skillnaden från sådana rabatter bli betydande för ditt sparande."
        ]
    }
},
{
    question: "Vad kostar Fundroid?",
    answer: {
        paragraphs: ["Genom modern teknik kan vi automatisera mycket av hanteringen och därigenom hålla nere den fasta avgiften till låga 0,30 % per år. Vår uppsida finns om vi lyckas särskilt väl med förvaltningen. Vid en överavkastning i relation till jämförelseindexet tar vi en ut en mindre del (tjugo procent av överavkastningen) och resten tillfaller dig som kund. Rättvist och transparent!"
            , "Om du väljer att ta hjälp från någon av de rådgivare som vi samarbetar med, t.ex. för att få hjälp med vilken kontotyp som passar dig bäst, eller vilken sparplan som du bör lägga upp för att nå dina uppsatta mål i framtiden, så kommer du och rådgivaren själva komma överens om en ersättningsmodell för dessa tjänster som passar er båda. Det är inget som Fundroid eller Strivo lägger sig i."
        ]
    }
},
{
    question: "Om jag redan har en depå, behöver jag öppna en ny depå för Fundroid?",
    answer: {
        paragraphs: ["Ja, du behöver öppna en ny separat depå (försäkringsdepå eller investeringssparkonto) för ditt Fundroid-sparande. Fundroid utgör en diskretionär portföljförvaltningstjänst som utförs av fondroboten Fundroid via Strivo och endast de värdepapper som ingår i förvaltningstjänsten får enligt gällande regelverk förvaras i depån. Det går självklart bra att öppna en separat depå hos oss för ditt andra sparande, där du själv kan välja vilka värdepapper som ska ingå."
        ]
    }
},
{
    question: "Vilken placeringshorisont bör jag ha när jag investerar med hjälp av Fundroid?",
    answer: {
        paragraphs: ["Rekommenderad placeringshorisont för Fundroid är minst 3 år. "]
    }
},
{
    question: "Vilken målsättning har Fundroid vad gäller avkastning och risk?",
    answer: {
        paragraphs: ["Fundroid siktar långsiktig på målavkastning på i genomsnitt 3 mån STIBOR+7 procent per år, med en målrisk (standardavvikelse) som över placeringshorisonten (3 år) förväntas uppgå till i genomsnitt 15 procent. Såväl målavkastning som målrisk kan under olika tidsperioder komma att variera kraftigt, beroende på Fundroids portföljinnehav vid den aktuella tidpunkten."
        ]
    }
},
{
    question: "Vilken målgrupp av kunder vänder sig Fundroid till?",
    answer: {
        paragraphs: ["Fundroid vänder sig till kunder i målgrupp 3 enligt Strivos s.k. ”målgruppsdefinition”, se {a} för mer information. Det innebär att Fundroid är lämplig för kunder som uppfyller minst följande kriterier: a) Besitter baskunskaper om fonder och fondsparande, b) har en hög förmåga att bära eventuella förluster, c) samt är ute efter en långsiktig tillväxt av kapitalet med en placeringshorisont på minst tre år."
        ]
    },
    textLink : {
        innerText: "www.strivo.se",
        linkStyle : {
            linkStyle: { color: "#d5b266" }
        },
        linkUrl : "https://www.strivo.se"
    }
},
{
    question: "Är det någon bindningstid?",
    answer: {
        paragraphs: ["Nej, du kan närsomhelst avsluta ditt Fundroid-sparande utan kostnad. Att avsluta tar dock trettio (30) dagar från att du anmäler att du vill avsluta tjänsten, vilket beror på att vi behöver avveckla den diskretionära portfölj som Fundroid har valt att investera i vid den aktuella tidpunkten och sälja av samtliga fondinnehav."
        ]
    }
},
{
    question: "Hur ofta kan jag göra uttag från mitt Fundroid-sparande?",
    answer: {
        paragraphs: ["Uttag kan göras närsomhelst och hur ofta som helst, dock ej under pågående omallokering (vilket sker under ett par bankdagar per månad). Självklart är det kostnadsfritt att göra uttag."
        ]
    }
},
{
    question: "Vad är minsta investeringsbelopp i Fundroid?",
    answer: {
        paragraphs: ["Minsta investeringsbelopp är 10 000 kronor vid engångsinsättning, alternativt 500 kronor per månad vid månadssparande."
        ]
    }
},
{
    question: "Går det att månadsspara i Fundroid?",
    answer: {
        paragraphs: ["Ja det går fint att månadsspara i Fundroid via autogiro. Lägsta belopp att månadsspara är för närvarade 500 kronor."
        ]
    }
},
{
    question: "Vilka risker är förknippade med Fundroid?",
    answer: {
        paragraphs: ["Alltid när du väljer att spara i fonder så är det alltid förknippat med risk. Det investerad kapital som placeras i Fundroid kan komma att både öka och minska i värde, vilket innebär att det inte går att garantera att du får tillbaka hela det investerade kapitalet eller garantera framtida avkastning. Det är också viktigt att tänka på och vara medveten om att historisk utveckling inte är någon garanti för framtida utveckling"
        ]
    }
}];       