export const ArticleHeaderRowData = {
    backgroundImagePath: "url(content/images/private-savings-page/bg.png)",
    iconImageSrc: "content/images/q-and-a-page/base_2.png",
    iconInnerImageSrc: "content/images/general-icons/money-savings.png",
    hPTextColData: {
        colXlSize: { size: 5, offset: 2 },
        colLgSize: { size: 7, offset: 1 },
        colXsSize: { size: 10 },
        headerStyles: { fontSize: "2rem", fontWeight: "600" },
        header: "Privat sparande i investeringssparkonto",
    }
};

export const ArticleBodyRowData = {
    backgroundImagePath: "url(content/images/private-savings-page/stock.png)",    
    colXlSize: { size: 8, offset: 2 },
    header: "Nettohandlat investeringssparkonto",
    bodyText: {
        paragraphs: ["Det investeringssparkonto som du öppnar för ditt Fundroid-sparande är inte ett vanligt investeringssparkonto som du hittar hos storbanken eller din nätmäklare, utan ett s.k. ”nettohandlat” investeringssparkonto. Det betyder att de fonder som förvaras på kontot handlas till nettopris, vilket i praktiken innebär en rabatt på fondens ordinarie förvaltningsavgift för dig som kund. Detta kan vi uppnå genom att vi återbetalar alla fondprovisioner eller andra typer av tredjepartsersättningar som fondbolagen normalt sett betalar till depåinstitut, banker, nätmäklare eller fonddistributörer. Dessa rabatter ligger normalt på cirka 50 procent av fondens förvaltningsavgift, vilket innebär att du genom Fundroid får några av marknadens bästa fonder till i genomsnitt halva priset. Och med en fondrobot som aktivt förvaltar fondportföljen åt dig.",
        ]
    }
};

export const HPTextColData = [{
    id: 1,
    colXlSize: { size: 8, offset: 2 },
    bodyText: { paragraphs: ["Som privatperson kan du enkelt och smidigt välja att öppna ett investeringssparkonto (ISK) hos Strivo för ditt Fundroid-sparande. Ett investeringssparkonto är ett konto för förvaring av värdepapper (t.ex. värdepappersfonder), och är ett alternativ till en traditionell värde¬pappersdepå eller en kapitalförsäkring. Skillnaden mellan dessa konton är framförallt skattemässig. Investerings¬sparkontot syftar till att underlätta handel med värde¬papper och andra finansiella tillgångar genom att du inte behöver ta hänsyn till skattekonsekvenser vid köp eller försäljning eller ta upp dessa i din deklaration. Observera att endast privatpersoner enligt gällande lagstiftning får lov att öppna ett investeringssparkonto.",
"Eftersom Fundroid är en diskretionär förvaltningstjänst som erbjuds av Strivo och vi behöver komma åt att förvalta ditt investeringssparkonto i enlighet med riktlinjer och placeringsreglementet för Fundroid, så kan du inte spara med Fundroid inom ramen för ett investeringssparkonto hos ett annat depåinstitut än Strivo.",
"Om du har BankID kan du snabbt och enkelt öppna ett investeringssparkonto hos Strivo genom att klicka här. Du fyller då i och signerar alla blanketter digitalt och slipper onödigt pappersarbete."] },
},
{
    id: 2,
    colXlSize: { size: 8, offset: 2 },
    header: "Beskattning av ISK",
    bodyText: { paragraphs: ["Idén bakom investeringssparkontot (ISK) är främst att förenkla för dig som privatperson, när du sparar i värdepapper. Istället för att skatta och deklarera varje enskild värdepappersaffär, betalar man som innehavare av investeringssparkontot en schablonskatt som baseras på förväntad avkastning.",
"Kapitalunderlaget beräknas årligen som en fjärdedel av summan för värdet av tillgångarna på investeringssparkkontot vid ingången av varje kvartal, insättningar till kontot samt överföringar av finansiella instrument.",
"Schablonintäkten beräknas genom att kapitalunderlaget multipliceras med statslåneräntan per den 30 november föregående år plus en procentenhet, med ett golv på 1,25 procent. Den summan beskattas med 30 procent.",
"I din deklaration tas schablonintäkten upp som en vanlig kapitalinkomst, från vilken du kan göra ränteavdrag. Just detta är en stor fördel jämfört med att spara i kapitalförsäkring, där avkastningsskatten inte kan kvittas mot andra eventuella kapitalförluster. Ett överskott i inkomstslaget kapital beskattas på sedvanligt sätt med 30 procent och ett underskott kan medföra skattereduktion.",
"Strivo beräknar och rapporterar schablonintäkten till Skatteverket."] },
}];
