import React from 'react';
import "./PerformanceTextRow.scss";
import { Row, Col } from 'reactstrap';

const PerformanceTextRow = (props) => {
    return (
        <Row className={props.highlight ? "highlight-row performance-text-row" : "performance-text-row"}>  
            <Col className="performance-text-header-col" style={{color: props.headerColor}} lg={12} xl={4}>
                <h6>{props.header}</h6>
            </Col>
            <Col xs={6} lg={4}>
                <h4>{props.secondColText}</h4>
            </Col>
            <Col xs={6} lg={4}>
            <h4 className="third-col-text text-xl-right">{props.thirdColText}</h4>
            </Col>
        </Row>
    )
}
export default PerformanceTextRow;