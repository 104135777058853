import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import "./LegalPage.scss";
import ArticleHeaderRow from '../../components/presentational/article-header-row/ArticleHeaderRow';
import HPTextCol from '../../components/presentational/hp-text-col/HPTextCol';
import ListUl from '../../components/presentational/list-ul/ListUl';

const _data = require('../../app-data/legal-page.js');

export class LegalPage extends Component {

    render() {
        const hpText = _data.HPTextColData ? _data.HPTextColData.map(col => <Row key={col.id}><HPTextCol {...col} /></Row>) : "";        
        return (
            <div id="legalPage">
                <ArticleHeaderRow {..._data.ArticleHeaderRowData} />
                {hpText}    
                <Row>
                   <Col lg={{size: 9, offset: 1}} xl={{size:8, offset: 2}}>
                       <ListUl {..._data.ListUlData}/>
                   </Col> 
                </Row>
            </div>
        );
    }
}
