import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import "./Footer.scss"

const Footer = (props) => {
    return (
        <Container fluid={true} id="appFooter">
            <Container>
                <Row className="footer-container-row">
                    <Col lg={6}>
                        <h5>Allt om Fundroid</h5>
                        <hr />
                        <Row>
                            <Col xs={6} className="footer-link-column">
                                <div><Link to="/sa-sparar-du">Så sparar du i Fundroid</Link></div>
                                <div><Link to="/avgifter">Avgifter</Link></div>
                                <div><Link to="/historisk-avkastning">Historisk avkastning</Link></div>
                                <div><Link to="/anvandarvillkor">Användarvillkor</Link></div>
                            </Col>
                            <Col xs={6} className="footer-link-column">
                                <div><Link to="/legal-information">Legal information</Link></div>
                                <div><Link to="/personuppgifter">Hantering av personuppgifter</Link></div>
                                <div><Link to="/cookies">Hantering av cookies</Link></div>
                                <div><Link to="/synpunkter">Synpunkter och klagomål</Link></div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={{size:5, offset:1}} className="footer-social-media-col">
                        <h5>Följ oss i sociala medier</h5>
                        <hr />
                        <a href="https://www.facebook.com/Fundroidse-105761754466963" target="_blank" rel="noopener noreferrer"><img alt="smedia.png" width="56" height="56" src="content/images/footer/facebook.png" /></a>
                        <a href="https://instagram.com/fundroid.se?igshid=1m6x3zxwvmzpy" target="_blank" rel="noopener noreferrer"><img alt="smedia.png" width="56" height="56" src="content/images/footer/instagram.png" /></a>
                        <a href="https://www.linkedin.com/company/fundroid-se" target="_blank" rel="noopener noreferrer"><img alt="smedia.png" width="56" height="56" src="content/images/footer/linkedin.png" /></a>                     
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default Footer;