import React, { Component } from 'react';
import { Row } from "reactstrap";
import "./AboutPage.scss";
import FundroidExplanatory from "../../components/presentational/fundroid-explanatory/FundroidExplanatory";
import HPTextCol from "../../components/presentational/hp-text-col/HPTextCol";

const _data = require('../../app-data/about-page.js');

export class AboutPage extends Component {

  render() {
    let firstContent = "";
    let secondContent = "";
    let thirdContent = "";
    if (_data.HPTextColData) {
      firstContent = <HPTextCol {..._data.HPTextColData[0]} />;
      secondContent = <HPTextCol {..._data.HPTextColData[1]} />;
      thirdContent = _data.HPTextColData.slice(2).map(col => <Row key={col.id}><HPTextCol {...col} /></Row>);
    }

    return (
      <div id="aboutPage">
        <Row id="aboutPageBlueWaveRow" style={{ backgroundImage: "url(content/images/about-page/bg.png)" }}>
          {firstContent}
        </Row>
        <Row>
          {secondContent}
        </Row>
        <FundroidExplanatory />
        {thirdContent}
      </div>
    );
  }
}
